import { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { MapContainer, Polygon, Marker, Popup, Tooltip, ZoomControl, ScaleControl, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import MapIncluder from './MapIncluder';
import "./Map.css";
import { useMapEvents } from "react-leaflet";
import VesselRedIc from './images/vesselRed.svg';
import VesselBlueIc from './images/vesselBlue.svg';
import VesselGreenIc from './images/vesselGreen.svg';
import VesselGreyIc from './images/vesselGreyIc.svg'
import TerminaBluelIc from './images/terminalBlue.svg'
import TerminaGraylIc from './images/terminalGray.svg'
import TerminalRedIc from './images/terminalRed.svg'
import Control from "react-leaflet-custom-control";
import LayersIcon from '@mui/icons-material/Layers';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import VectorGrid from './VectorGrid';
import StarLinkGrid from './StarLinkGrid';
import { ButtonGroup, Button } from "@mui/material";
import './smoothWheelZoom';
import * as tj from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import LayerDetailsMaps from "../../pages/TowerVesselDetails/LayerDetails/LayerDetails";

let DefaultIcon = L.icon({
   iconUrl: icon,
   shadowUrl: iconShadow,
   iconSize: [24,36],
   iconAnchor: [12,36]
 });

 L.Marker.prototype.options.icon = DefaultIcon; 


const MapLeaflet = (props) => {

  const [isOuterHexagonEnabled, setIsOuterhexagaonEnabled] = useState(true);
  const [isOuterStarLinkEnabled, setIsOuterStarLinkEnabled] = useState(true)
  const [isToolTip, setIsToolTip] = useState(false);
  const [map, setMap] = useState(null);
  const [mapPosition, setMapPosition] = useState([35, -35]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedLastLocIndex, setSelectedLastLocIndex] = useState(-1);
  const [kml, setKml] = useState(null);
  const [kml2, setKml2] = useState(null);

  const { polyColor, hexagons, isHexogonsEnabled, handleVesselClick, selectedvessels, handleChartOpen, isChartClosed, outerHexData,
    innerHexData, vesselCurrentLocation, selectedLayer, mapHeight, isMultiIcons,
    outerHexOpacity, innerHexOpacity, locationFilter, isPopUpOpen, handleLayerButton, handleHexClick, selectedCarrier, h3Index, isVesselLastLocClickEnabled, handleLastLocVesselClick,
    selctedVesselId, isVsatDataSource, defaultStartDate, defaultEndDate, isSpecialPrrmEnabled,starLinkData, selectedDataSource,starLinkCoverageFour,terminalLocationDetails,
    isAllStarlinkLocation, isStarlinkLatencyMacro, isStarlinkLatencyMicro, edgeVal, isLteMicro, isLteMacro, selectedOu, k4Ids, 
    getLayerDetails, setLayersOpen, layerSelectionDetails, isLocationEnabled, layersOpen} = props;

  useEffect(()=> {
      if (terminalLocationDetails !== undefined && terminalLocationDetails.length > 0 && terminalLocationDetails?.length <= 2 ) {
        let indexPosition = terminalLocationDetails.length - 1
        let vesselDataLastCord = terminalLocationDetails[indexPosition];
        if (mapPosition[0] !== vesselDataLastCord?.lat) {
          if(vesselDataLastCord?.lat && vesselDataLastCord?.long) {
            const mapContainer = map?.getContainer();
            if(mapContainer) {
              map.setView([vesselDataLastCord?.lat , vesselDataLastCord?.long ], map.getZoom());
            }
          
          } else {
            console.log("CORDINATES EMPTY", vesselDataLastCord);
          }
          
        }
    }
  },[terminalLocationDetails])

  const style = {
    width: "100%",
    height: mapHeight
  };

  useEffect(() => {
    setSelectedIndex(-1)
    setSelectedLastLocIndex(-1);
  },[selectedOu, k4Ids])


  function MyComponent() {
    const mapEvents = useMapEvents({
      zoomend: () => {
        let zoomLvl = mapEvents.getZoom();



        if (zoomLvl < 11) {
          if (!isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(true);
          }
        } else if (zoomLvl >= 11) {
          if (isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(false);
          }
        }
        if (zoomLvl < 9) {
          if (!isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(true);
          }
        } else if (zoomLvl >= 9) {
          if (isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(false);
          }
        }
        if (zoomLvl >= 8) {
          if (!isToolTip) {
            setIsToolTip(true);
          }
        } else if (zoomLvl < 8) {
          if (isToolTip) {
            setIsToolTip(false);
          }
        }
      }
    });
    return null
  }

  const getVesselRedIconOnCondition = (angle, index, iconClasfier, source) => {
        return selectedLastLocIndex === index ? <img src={source=='STARLINK'? TerminalRedIc :VesselRedIc} alt={'Tower'} className={source=='STARLINK'? "starlinklBigIcon":"vesselRedIcon"} style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source=='STARLINK'? TerminalRedIc:VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
  }
  const VesselRedIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselRedIconOnCondition(angle, index, iconClasfier, source)
  );

  const getVesselGrayIconOnCondition = (angle, index, iconClasfier, source) => {
    return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Tower'} className="vesselRedIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
}

  const VesselGrayIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselGrayIconOnCondition(angle, index, iconClasfier, source)
  );

  const getVesselIconOnCondition = (angle, index, iconClasfier, source) => {
    switch (iconClasfier) {
      case 1:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 2:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 3:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 4:
        return locationFilter?.alerting ? <img src={VesselGreenIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      default:
        return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaBluelIc:VesselBlueIc} alt={'Tower'} className="starlinklBigIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaBluelIc: VesselBlueIc} alt={'Vessel'} className={selectedLastLocIndex === index ? "starlinklBigIcon" : "vesselIcon"} style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
    }
  }

  const VesselBlueIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselIconOnCondition(angle, index, iconClasfier, source)
  );

  const CustomVesselBlueIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselBlueIcon(angle, index, iconClasfier, source)
  });

  const CusomVesselRedIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselRedIcon(angle, index, iconClasfier,source)
  });

  const CusomVesselGrayIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselGrayIcon(angle, index, iconClasfier, source)
  });

  useEffect(() => {
    if (!isChartClosed) {
      setSelectedIndex(-1)
      setSelectedLastLocIndex(-1);
    }
  }, [isChartClosed])

  useEffect(() => {
    fetch(
      "https://cdn.k4mobility.com/cdn/S900_kasat_v22_coverage.kml"
    )
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        const converted = tj.kml(kml);
        rewind(converted, false);
        setKml(converted);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://cdn.k4mobility.com/cdn/vs2-sailor900.kml"
    )
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        const converted = tj.kml(kml);
        rewind(converted, false);
        setKml2(converted);
      });
  }, []);

  useEffect(() => {
    if (vesselCurrentLocation && vesselCurrentLocation.length > 0 && selctedVesselId) {
      setSelectedLastLocIndex(vesselCurrentLocation.findIndex(vessel => vessel?.locationId === selctedVesselId));
    } else {
      setSelectedLastLocIndex(-1);
    }
  }, [selctedVesselId, vesselCurrentLocation])


  const handleVesselClickInner = (e, vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails, isWindowEnabled) => {
    handleChartOpen(true);
    if (selectedIndex === e?.target?.options?.index) {
      setSelectedIndex(-1);
      handleChartOpen(false);
      return
    }
    setSelectedIndex(e?.target?.options?.index);
    handleVesselClick(vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails,isWindowEnabled);
  }

  const handleLastLocationVesselClick = (e, item) => {
    if (selectedLastLocIndex === e?.target?.options?.index) {
      setSelectedLastLocIndex(-1);
      handleLastLocVesselClick(null);
      return
    }
    setSelectedLastLocIndex(e?.target?.options?.index);
    handleLastLocVesselClick(item);
  }

  const handleHexClickInner = (e, resolution) => {
    handleHexClick(e, resolution);
  }

  const alertMap = {
    50: "Ethernet Slow 10mbps",
    51: "Ethernet Slow 100mbps",
    52: "Power Supply Thermal Throttling",
    53: "Actuator Motor Stuck",
    54: "Mast Not Vertical",
    55: "Disabled No Active Account",
    56: "Disabled Too Far From Service Address",
    57: "Disabled No Service In Ocean",
    58: "Disabled Invalid Country",
    59: "Moving While Not Mobile",
    60: "Disabled Moving Too Fast",
    61: "Power Disconnect Detected",
    62: "Pop Change",
    79: "Software Update Reboot Pending",
    63: "Unable To Align",
    64: "High Sky Obstruction",
    65: "High Sky Obstruction Mobile",
    66: "High Time Obstruction",
    67: "High Time Obstruction Mobile",
    80: "Thermal Shutdown",
    81: "Thermal Throttling",
    82: "Software Update Reboot Pending",
    "over_threshold": "Over Quota Threshold",
    "over_limit": "Over Max Quota",
    "offline_for_15m": "Offline For More Than 15 Minutes",
    "offline_for_1h": "Offline For More Than 1 Hour",
    "offline_for_6h": "Offline For More Than 6 Hours",
};


  const vesselPopUp = (item) => {
    if(item?.starlinkKpi && item?.edgeKpi) {
      let activeAlerts = ""
      if(item?.starlinkKpi?.active_alerts) {
        activeAlerts = JSON.parse(item?.starlinkKpi?.active_alerts);
      }
      let alertMsgList = "";
      if(activeAlerts && activeAlerts?.length > 0) {
        alertMsgList = activeAlerts.map((item) => {
          return alertMap[item]
        } )
      }
      if(alertMsgList && alertMsgList.length > 0 ) {
        alertMsgList = alertMsgList.join(',')
      }
      return <><Popup className="vesselBothPopUp" closeButton={false}>
        <div className="popupColumn">
          <div className="onHoverName">{item?.name?.toUpperCase()}</div>
        </div>
        <div className="popupColumn">
          <div className="onHoverNameHEAD">STARLINK</div>
          <div className="onHoverName">{item?.starlinkName}</div>
        </div>
      </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    }
    if(item?.src == "STARLINK" && item?.starlinkKpi) {
      console.log("starlink123",item)
      let activeAlerts = ""
      if(item?.starlinkKpi?.active_alerts) {
        activeAlerts = JSON.parse(item?.starlinkKpi?.active_alerts);
      }
      let alertMsgList = "";
      if(activeAlerts && activeAlerts?.length > 0) {
        alertMsgList = activeAlerts.map((item) => {
          return alertMap[item]
        } )
      }
      if(alertMsgList && alertMsgList.length > 0 ) {
        console.log("alertMsgList",alertMsgList)
        alertMsgList = alertMsgList.join(',')
      }
     
      return <><Popup className="vesselLocPopUp" closeButton={false}>
      <div className="onHoverNameHEAD">STARLINK</div>
      <div className="onHoverName">{item?.name?.toUpperCase()}</div>
    </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    
    } else {
      return <><Popup className="vesselLocPopUp" closeButton={false}>
      <div className="onHoverName">{item?.name?.toUpperCase()}</div>
    </Popup>
      {!isOuterHexagonEnabled && <Tooltip
        direction="right" offset={[0, 0]} opacity={1} permanent><div>{item.name}</div>
      </Tooltip>}
    </>
    }
  }
  const getVariableMovement = (zoom) => {
    if (zoom < 12) {
      return 0.6;
    } else if (zoom >= 12 && zoom < 14) {
      return 0.16;
    } else if (zoom >= 14 && zoom < 16) {
      return 0.04;
    } else if (zoom >= 16 && zoom < 18) {
      return 0.006;
    } else if (zoom >= 18 && zoom <= 19) {
      return 0.004;
    } else if (zoom > 19 && zoom <= 22) {
      return 0.0006
    }
    else {
      return 0.04;
    }
  }

  return (
    <div id="map" className='LeafLetMaps' style={isPopUpOpen ? style : style} >
      <MapContainer
        center={mapPosition}
        zoom={4}
        minZoom={3}
        maxZoom={22}
        style={{ height: "100%" }}
        whenCreated={setMap}
        attributionControl={false}
        zoomControl={false}
        worldCopyJump={false} // Disable world copy jumping
        maxBounds={[[-90, -180], [90, 180]]} // Constrain to world bounds
        maxBoundsViscosity={1.0} // Prevent overscrolling

        //  zoomAnimation={true}
        // wheelPxPerZoomLevel={240}
        // scrollWheelZoom={true}  // disable original zoom function
        // smoothWheelZoom={true}
        // smoothSensitivity={1}
        scrollWheelZoom={false}
        smoothSensitivity={0.90}
        smoothWheelZoom={true}
        fadeAnimation={true}
        preferCanvas={false}
        boxZoom={true}
      >
        <ScaleControl className="mapScale" position="bottomright" />
        <ZoomControl position="topright" />
        <Control position="topright" className="customButtons" >
          <ButtonGroup orientation="vertical" className="mapCustButtonsGroup" >
            <Button title='Layers' id="layersSel"
              // color={active === "adjust" ? "primary" : "inherit"}
              onClick={() => handleLayerButton()}
            >
              <LayersIcon className="layersIcon" color="black" />
             
            </Button>
           { selectedvessels && <Button title='Auto Chart' id="layersSel" onClick ={() => handleVesselClickInner(null, defaultStartDate, defaultEndDate, null,false)}>
              <AutoGraphOutlinedIcon  className="layersIcon" color="black"/>
            </Button>}
          </ButtonGroup>
        </Control>
        <MyComponent />
        {layersOpen && <div className="maps-layers-new-container" id="maps-layers-new-container">
          <LayerDetailsMaps getLayerDetails={getLayerDetails} setOpen={setLayersOpen} layerSelectionDetails={layerSelectionDetails} isLocationEnabled={(isLocationEnabled)} isSpecialPrrmEnabled={isSpecialPrrmEnabled} />
        </div>}

        <MapIncluder isDark={false} isOpeStreet={true} />
        {isHexogonsEnabled && !isVsatDataSource ? Object.entries(hexagons).map(([weight, polygons]) => {
          return <Polygon
            positions={polygons}
            pathOptions={{
              fillColor: polyColor,
              color: polyColor,

            }}
          />;
        }) : null}
        {edgeVal !== "NA" && isAllStarlinkLocation && terminalLocationDetails !== undefined && terminalLocationDetails.length > 0 && terminalLocationDetails.map((item, index) => {
          if (item == undefined || item?.lat === null || item?.long === null) {
            return;
          }
          let angle = item?.heading ;
          let iconClasfier = item?.iconClasfier;
          let alertingList = item?.alertingList
          let source = item?.src
          return <Marker
            key={index}
            index={index}
            position={[item?.lat, item?.long]}
            icon={item?.status == 0 ? CusomVesselRedIcon(angle, index, iconClasfier, source): item?.status == 2 ?  CusomVesselGrayIcon(angle, index, iconClasfier, source) : CustomVesselBlueIcon(angle, index, iconClasfier, source)}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                setTimeout(() => {
                  e.target.closePopup();
                }, 10);
              },
              click: (e) => {
                // handleClick(e, true);
                e.target.closePopup();
                if (isVesselLastLocClickEnabled) {
                  let mapZoom = map.getZoom();
                  let VariableMovement = getVariableMovement(mapZoom);
                  let clickedLatLng = e.latlng;
                  // let VariableMovement = calculateOffset(mapZoom);
                  const mapContainer = map?.getContainer();
                  if(mapContainer) {
                    const fixedPosition = {
                      x: mapContainer.clientWidth / 4,
                      y: mapContainer.clientHeight / 4
                   };
                    let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
                    // let newLat = clickedLatLng.lat - (fixedPointLatLng.lat - map.getCenter().lat);
                    // let newLng = clickedLatLng.lng - (fixedPointLatLng.lng - map.getCenter().lng);
                    // map.setView([newLat, newLng], map.getZoom());
                  }
                  handleLastLocationVesselClick(e, item);
                }

              },
            }}
          >
            {isMultiIcons ? vesselPopUp(item) : vesselPopUp(item)}
          </Marker>
        })
        }
        {isStarlinkLatencyMacro  && starLinkData  && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkData} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={1000} selectedDataSource={selectedDataSource} resolution={3}/> }
        {isStarlinkLatencyMicro && starLinkData && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkCoverageFour} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} selectedDataSource={selectedDataSource} resolution={4}/> }
        {isLteMacro && outerHexData && <VectorGrid assets={outerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} /> }
        {isLteMicro && innerHexData && <VectorGrid assets={innerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} resolution={7}/>} 
        {kml && layerSelectionDetails?.Viasat1 && isSpecialPrrmEnabled && <GeoJSON  data={kml} />}
        {kml2 && layerSelectionDetails?.Viasat2 && isSpecialPrrmEnabled && <GeoJSON data={kml2} />}
      </MapContainer>
    </div> 
  );
};

export default MapLeaflet;



