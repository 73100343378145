import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import ServiceAccounts from "./ServiceAccounts";
import ServiceLines from "./ServiceLines";
import { Grid } from "@mui/material";
import UserTerminals from "./UserTerminals";
import AuditLogs from "./AuditLogs";
import Navigation from "./Navigation";
import { StarlinkAdminNavTabs } from "./types";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { SyntheticEvent, useEffect, useState } from "react";
import { event } from "react-ga";
import TabsComponent from "../NewTabs";
import Organization from "./Organization";
import CustomPlans from "./CustomPlans";

interface StarlinkAdminProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    match: any;
}

function StarlinkAdmin(props: StarlinkAdminProps) {

    const { authReducer, errorReducer, history, location, match } = props;

    const [activeTab, setActiveTab] = useState<StarlinkAdminNavTabs>(match?.params?.tab ? match.params.tab : "service_line");

    const handleChangeActiveTab = (event: SyntheticEvent, newValue: StarlinkAdminNavTabs) => {
        changeActiveTab(newValue);
    };

    const changeActiveTab = (newValue: StarlinkAdminNavTabs) => {
        const currentParams = getDecodeURI(location?.search);
        const params = {};
        for (let key of ['startDate', 'endDate', 'interval', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess']) {
            if (currentParams.hasOwnProperty(key)) {
                params[key] = currentParams[key];
            }
        }
        history.replace({ pathname: `/starlink/admin/${newValue}`, search: getEncodedURI(params) })
        setActiveTab(newValue);
    }

    useEffect(() => {
        if (match?.params?.tab) {
            changeActiveTab(match.params.tab);
        } else {
            changeActiveTab("service_line");
        }
    }, [match?.params?.tab]);

    const tabs = [
        { tabName: 'Service Account', tabValue: 'service_account', pathName: '/starlink/admin/service_account' },
        { tabName: 'Organization', tabValue: 'organization', pathName: '/starlink/admin/organization' },
        { tabName: 'Custom Plans', tabValue: 'custom_plans', pathName: '/starlink/admin/custom_plans' },
        { tabName: 'Service Line', tabValue: 'service_line', pathName: '/starlink/admin/service_line' },
        { tabName: 'User Terminals', tabValue: 'user_terminal', pathName: '/starlink/admin/user_terminal' },
        { tabName: 'Audit Logs', tabValue: 'audit_logs', pathName: '/starlink/admin/audit_logs' },
    ]

    return (
        <Grid classes={{ root: 'starlink--base--mtb_07x'}}>
            {/* <Navigation value={activeTab} onChange={handleChangeActiveTab} /> */}
            <TabsComponent tabs={tabs} handleChangeTab={handleChangeActiveTab} activeTab={activeTab} isSubTabs={true} />
            <Switch>
                <Route path="/starlink/admin/service_account" component={ServiceAccounts} />
                <Route path="/starlink/admin/organization" component={Organization} />
                <Route path="/starlink/admin/service_line" component={ServiceLines} />
                <Route path="/starlink/admin/user_terminal" component={UserTerminals} />
                <Route path="/starlink/admin/custom_plans" component={CustomPlans} />
                <Route path="/starlink/admin/audit_logs" component={AuditLogs} />
            </Switch>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {})(StarlinkAdmin)
);