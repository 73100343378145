import React,{ useState, useEffect} from "react";
import { Grid, Button, Tabs, Tab } from "@mui/material";
import { withRouter } from "react-router-dom";
import { updatePermission } from "../../actions/Users/authenticate";
import { connect, useDispatch } from "react-redux";
import InventoryDeployedTable from "../../components/InventoryDeployedTable";
import InventoryWarehouseTable from "../../components/InventoryWarehouseTable";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import {BC_INVENTORY_DEPLOYED, BC_INVENTORY_WAREHOUSE, BC_INVENTORY_CONFIG} from "../../constants/Constants";
import {SIDE_MENU_ITEM_INVENTORY} from "../../utils/constants";
import { checkLoggedInUserAuthorizedToViewPage } from "../../utils/util";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import NotAuthorized from "../NotAuthorized";
import "./inventory.css"
import _ from "lodash";
import { getInventoryTopWidgetsRegisteredCount, getInventoryTopWidgetKassCount, getInventoryTopWidgetWarehouseCount } from "../../actions/Users/authenticateInventory";
import BackupConfig from "../../components/InventoryConfig/BackupConfig";
import DefaultConfig from "../../components/InventoryConfig/DefaultConfig";
import ConfigStatus from "../../components/InventoryConfig/ConfigStatus";
import GroupActions from "../../components/InventoryGroupActions/index"
import ManualBackupConfig from "../../components/InventoryConfig/ManualBackupConfig";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import { INVENTORY_TOP_WIDGET_KAAS_COUNT, INVENTORY_TOP_WIDGETS_REGISTERED_COUNT } from "../../actions/types";
import SiteInfo from "../../components/SiteInfo";
import { getVesselsListingForPrivate } from "../../actions/Users/authenticateService";
import InventoryDeployedPrivate from "../../components/InventoryDeployedTable/InventoryDeployedPrivate";
import TabsComponent from "../../components/NewTabs";

const InventoryPage = (props) => {
  const { authReducer, history, location, updatePermission, getInventoryTopWidgetsRegisteredCount, getInventoryTopWidgetKassCount, getInventoryTopWidgetWarehouseCount,getVesselsListingForPrivate } = props;
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const [timePeriod, setTimePeriod] = useState({});
  const EDGE_ADMINISTRATION = "edge-administration";
  const VIEW_INVENTORY = "view-inventory"
  const not_authorized_page_title = "Inventory";
  const not_authorized_page_message = "You are not authorised to view Inventory";
  const viewInventoryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]["service"];
  const viewInventoryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]["function"];
  const view_Warehouse= "view-inventory"
  const viewWarehouseService = SERVICE_FUNCTION_TO_FEATURE[view_Warehouse]["service"];
  const viewWarehouseFunction = SERVICE_FUNCTION_TO_FEATURE[view_Warehouse]["function"];
  BC_INVENTORY_DEPLOYED[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name : BC_INVENTORY_DEPLOYED[0]["title"];
  BC_INVENTORY_WAREHOUSE[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name: BC_INVENTORY_WAREHOUSE[0]["title"];
  BC_INVENTORY_CONFIG[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name: BC_INVENTORY_CONFIG[0]["title"];
  let breadcrumbInfo  = BC_INVENTORY_DEPLOYED;
  const DEPLOYED = "Deployed";
  const WAREHOUSE = "Warehouse";
  let _q = getDecodeURI(location?.search);
  const [selectedButton, setSelectedButton] = useState<any>(_q.hasOwnProperty("tab") ? _q.tab : DEPLOYED);
  const setBreadcrumbInfo: any = React.useRef();
  const [topWidgetsRegisteredCount, setTopWidgetsRegisteredCount] = useState<any>("-");
  const [topWidgetsWarehouseCount, setTopWidgetsWarehouseCount] = useState<any>("-");
  const [topWidgetsKaasCount, setTopWidgetsKaasCount] = useState<any>("-")
  const dispatch = useDispatch()

  const CONFIG = 'Config';
  const BACKUP_CONFIG = 'Backup';
  const DEFAULT_CONFIG = 'Default';
  const LOGS="Logs"
  const GROUP_ACTIONS = "GroupActions"
  const SITE_INFO = "SiteInfo";
  const PRIVATE_ORGS="PrivateOrgs"
  const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
  const [selectedConfigTab, setSelectedConfigTab] = useState(_q.hasOwnProperty('config') ? _q.config : DEFAULT_CONFIG);
  const [ispRivateOrgzEnabled, setIsPrivateOrgzEnabled] = useState(false);

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_INVENTORY !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_INVENTORY };
  }

  useEffect(() => {
    if(authReducer?.selectedOu && authReducer?.selectedOu?.name) {
      getVesselsListingForPrivate(authReducer?.selectedOu?.name,'','','')
    }
  },[authReducer?.selectedOu])

  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  // useEffect(() => {
  //   if(authReducer?.getVesselsListing?.locations && authReducer?.privateVesselList?.locations) {
  //     if(authReducer?.privateVesselList?.locations?.length > authReducer?.getVesselsListing?.locations?.length) {
  //       let diff = getDifference(authReducer?.privateVesselList?.locations,authReducer?.getVesselsListing?.locations);
  //       if(diff.length > 0) {
  //         setIsPrivateOrgzEnabled(true);
  //       } else {
  //         setIsPrivateOrgzEnabled(false);
  //         _q.tab == 'PrivateOrgs' && doNavigateToDeployed();
  //       }
  //     } else {
  //         setIsPrivateOrgzEnabled(false);
  //         _q.tab == 'PrivateOrgs' && doNavigateToDeployed();
  //     }
  //   }
  // },[authReducer?.getVesselsListing, authReducer?.privateVesselList])


  useEffect(() => {
      const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
      const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
      const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
      setIsAuthorizedToViewBetaFeature(authorizedBetaUser)
      if(CONFIG === selectedButton){
        breadcrumbInfo = BC_INVENTORY_CONFIG;
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewWarehouseService, viewWarehouseFunction);
        setIsAuthorizedToViewPage(authorized);
        authorized && permissionChanges(true, false);
      }
      else if(WAREHOUSE === selectedButton){
        breadcrumbInfo = BC_INVENTORY_WAREHOUSE;
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewWarehouseService, viewWarehouseFunction);
        setIsAuthorizedToViewPage(authorized);
        authorized &&  permissionChanges(true, false);
      } else if (DEPLOYED == selectedButton){
        breadcrumbInfo = BC_INVENTORY_DEPLOYED;
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewInventoryService, viewInventoryFunction);
        setIsAuthorizedToViewPage(authorized);
        authorized && permissionChanges(true, false);
      } else if (LOGS == selectedButton){
        setIsAuthorizedToViewPage(authorizedBetaUser);
        authorizedBetaUser && permissionChanges(true, true);
      } else if (GROUP_ACTIONS == selectedButton){
        setIsAuthorizedToViewPage(authorizedBetaUser);
        authorizedBetaUser && permissionChanges(true, false);
      } else if (SITE_INFO == selectedButton) {
        setIsAuthorizedToViewPage(authorizedBetaUser);
        authorizedBetaUser && permissionChanges(true, false);
      } else if (PRIVATE_ORGS == selectedButton) {
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewWarehouseService, viewWarehouseFunction);
        setIsAuthorizedToViewPage(authorized);
        authorized && permissionChanges(true, false);
      }
      setBreadcrumbInfo.current(breadcrumbInfo);
  
  }, []);

  const permissionChanges = (showOu, showSites) => {
    const info = {
      permission: {
        service: viewInventoryService,
        serviceFunction: viewInventoryFunction,
      },
      isMultiVessel: showSites,
      isSingleVessel: false,
      showOu: showOu,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    updatePermission(info);
  }

  const doNavigateToDeployed = () => {
    permissionChanges(true, false);
    setSelectedButton(DEPLOYED);
    if(breadcrumbInfo && breadcrumbInfo[1] && breadcrumbInfo[1].hasOwnProperty("link")){
      delete breadcrumbInfo[1]["link"];
    }

    if(breadcrumbInfo && breadcrumbInfo[2]){
      breadcrumbInfo[2]["title"] = DEPLOYED;
      setBreadcrumbInfo.current(breadcrumbInfo);
    }
    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    delete params.tab;
    delete params.config;
    doNavigate(location.url, params);
  }

  const doNavigateToWarehouse = () => {
    permissionChanges(true, false);
    setSelectedButton(WAREHOUSE);
    breadcrumbInfo[1]["link"] =  "/inventory";
    if(breadcrumbInfo && breadcrumbInfo[2]){
      breadcrumbInfo[2]["title"] = WAREHOUSE;
      setBreadcrumbInfo.current(breadcrumbInfo);
    }
    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    params.tab = WAREHOUSE;
    delete params.config;
    doNavigate(location.url, params);
  }

  const doNavigatetoTab= (tab, showSitesDropdown) =>{
    permissionChanges(true, showSitesDropdown);
    setSelectedButton(tab);

    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.size;
    delete params.sortBy;
    delete params.sortOrder;
    params.tab = tab;
    delete params.config;
    doNavigate(location.url, params);
  }

  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    if (authReducer?.getVesselsListing?.locations) {
      getTopWidgetsData(authReducer.selectedOu.name, authReducer.selectedOu.id);
    } else {
      setTopWidgetsRegisteredCount('-')
      setTopWidgetsWarehouseCount('-')
      setTopWidgetsKaasCount("-")
    }
  }, [authReducer.getVesselsListing, selectedButton]);

  const getTopWidgetsData = (selectedOuName, selectedOuId) => {
    if(selectedButton == DEPLOYED || selectedButton == WAREHOUSE){
      getInventoryTopWidgetsRegisteredCount(selectedOuId,selectedOuName)
      // getInventoryTopWidgetKassCount(selectedOuId,selectedOuName);
      getInventoryTopWidgetWarehouseCount(selectedOuId);
    }
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.inventoryTopWidgetsRegisteredCount) ) {
      if(authReducer.inventoryTopWidgetsRegisteredCount?.data){
         let registeredCount =authReducer.inventoryTopWidgetsRegisteredCount.data?.registered
         setTopWidgetsRegisteredCount(registeredCount)
      }
      dispatch({type: INVENTORY_TOP_WIDGETS_REGISTERED_COUNT, payload: {}})
    }
  }, [authReducer.inventoryTopWidgetsRegisteredCount]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.inventoryTopWidgetsKaasCount) ) {
      if(authReducer.inventoryTopWidgetsKaasCount?.data && authReducer.inventoryTopWidgetsKaasCount.data.hasOwnProperty("totalCount")){
         let kassCount =authReducer.inventoryTopWidgetsKaasCount.data?.totalCount 
         setTopWidgetsKaasCount(kassCount)
      }

      dispatch({type: INVENTORY_TOP_WIDGET_KAAS_COUNT, payload: {}})
    }
  }, [authReducer.inventoryTopWidgetsKaasCount]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.inventoryTopWidgetsWarehouseCount) ) {
      if(authReducer.inventoryTopWidgetsWarehouseCount?.data && authReducer.inventoryTopWidgetsWarehouseCount.data.hasOwnProperty("count")){
         let warehouse =authReducer.inventoryTopWidgetsWarehouseCount.data?.count; 
         setTopWidgetsWarehouseCount(warehouse);
      }
      authReducer.inventoryTopWidgetsWarehouseCount = {};
    }
  }, [authReducer.inventoryTopWidgetsWarehouseCount]);

  const doNavigateToConfig = () => {
    permissionChanges(true, false);
    setSelectedButton(CONFIG);
    setSelectedConfigTab(DEFAULT_CONFIG)
    breadcrumbInfo[1]["link"] =  "/inventory";
    if(breadcrumbInfo && breadcrumbInfo[2]){
      breadcrumbInfo[2]["title"] = CONFIG;
      setBreadcrumbInfo.current(breadcrumbInfo);
    }
    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    params.tab = CONFIG;
    doNavigate(location.url, params);
  }

  const doNavigateToConfigTabs = (tab) => {
    setSelectedConfigTab(tab)
    let params: any = getDecodeURI(location?.search);
    params.config = tab;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    delete params.search;
    doNavigate(location.url, params);
  }

  const doNavigateToBackupTab = () => {
    doNavigateToConfigTabs(BACKUP_CONFIG)
  }

  const doNavigateToDefaultTab = () => {
    doNavigateToConfigTabs(DEFAULT_CONFIG)
  }


const handleSiteClick = (row) => {
  let params: any = getDecodeURI(location?.search);
  row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
  delete params.page;
  delete params.limit;
  delete params.sort;
  delete params.sortOrder;
  delete params.search;
  doNavigate(params);
}

  const doNavigateTab = (pathname, tabName, showOu, showSites) => {
    permissionChanges(true, tabName == LOGS ? true : false);
    const currentParams = getDecodeURI(location?.search);
    const params: any = {};
    if (currentParams.ouName) {
      params['ouName'] = currentParams.ouName;
    }
    if (currentParams.ouId) {
      params['ouId'] = currentParams.ouId;
    }
    if (currentParams.k4Ids) {
      params['k4Ids'] = currentParams.k4Ids;
    }
    if (currentParams.sideNav) {
      params['sideNav'] = currentParams.sideNav
    }
    if (currentParams.interval) {
      params['interval'] = currentParams.interval
    }
    if (currentParams.startDate && currentParams.startDate > 1) {
      params['startDate'] = currentParams.startDate
    }
    if (currentParams.endDate && currentParams.endDate > 1) {
      params['endDate'] = currentParams.endDate
    }

    params.tab = tabName;
    history.push({ pathname, search: getEncodedURI(params) });
    setSelectedButton(tabName);
  }

  const tabs = [
    { tabName: 'Deployed', tabValue: DEPLOYED, pathName: '/inventory', ouDropdown: true, sitesDropdown: true },
    { tabName: 'Deployed Private', tabValue: PRIVATE_ORGS, pathName: '/inventory', hideTab: localStorage.getItem("IS_PRIVATE_ENABLED") == "true" ? 'No' : 'Yes' },
    { tabName: 'Warehouse', tabValue: WAREHOUSE, pathName: '/inventory' },
    { tabName: 'CM', tabValue: CONFIG, pathName: '/inventory' },
    { tabName: 'Logs', tabValue: LOGS, pathName: '/inventory' },
    { tabName: 'Group Actions', tabValue: GROUP_ACTIONS, pathName: '/inventory' },
    { tabName: 'Site Info *', tabValue: SITE_INFO, pathName: '/inventory', hideTab: localStorage.getItem("isBetaUser") === "true" ? 'No' : 'Yes' },
  ];

  return(
    <div>
    <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} loading={authReducer?.inventoryListingLoading || authReducer?.setVesselListingLoading || authReducer?.userPreferencesLoading || authReducer.setDeviceHistoryLoading || authReducer.setUpgradeInventoryLicenseLoading} setTimePeriod={setTimePeriod} handleSiteClick={handleSiteClick} showWantType={false} showSummaryPeriod={false} userGuide={[true, 'inventory-user-guide']}></CommonFilters>
    <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedButton} />
      <Grid item container className="inventoryListContainer">
        <Grid item className="inventoryListDataContainer">
          {
            isAuthorizedToViewPage ? (
              <div className="inventory-content">
                <Grid className={selectedButton !== CONFIG ? "inventory-deployed-container" : ""}>
                { selectedButton == DEPLOYED || selectedButton == WAREHOUSE? <Grid className="inventoryContailer_Tabs">
                    <Grid container item xs={12} className="inventory-top-widgets">
                      <Grid item xs={12} sm={3} md={3} lg={3} className="deployed-widget"><div className="key">Registered</div> <div className="value">{topWidgetsRegisteredCount}</div></Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} className="warehouse-widget"><div className="key">Warehouse</div>  <div className="value">{topWidgetsWarehouseCount}</div></Grid>
                      {/* <Grid item xs={12} sm={3} md={3} lg={3} className="kaas-widget"><div className="key">KaaS</div> <div className="value">{topWidgetsKaasCount}</div></Grid> */}
                    </Grid>
                  </Grid>: null}
                {selectedButton === WAREHOUSE ? <InventoryWarehouseTable permissionChanges={permissionChanges}/> :
                selectedButton === CONFIG ? 
                <Grid>
                  <Grid container className="inventory-config-container">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container className="inventory-config-tabs">
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button className={selectedConfigTab == DEFAULT_CONFIG ? 'backupConfig-active-tab' : 'backupConfig-inactive-tab'} onClick={doNavigateToDefaultTab}>Configurations</Button>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button className={selectedConfigTab == BACKUP_CONFIG ? 'backupConfig-active-tab' : 'backupConfig-inactive-tab'} onClick={doNavigateToBackupTab}>Backups</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                      { selectedConfigTab == DEFAULT_CONFIG ? <div className="default-manual-config-table-data"><DefaultConfig/><ManualBackupConfig /></div>:
                        selectedConfigTab == BACKUP_CONFIG ? <BackupConfig /> : null
                      }
                  </Grid>
                </Grid>
                 :
                 selectedButton === LOGS ? <ConfigStatus className="inventory-logs-tab-content" />
                 : selectedButton === GROUP_ACTIONS ? <GroupActions/>
                 : localStorage.getItem("isBetaUser") === "true" && selectedButton === SITE_INFO ?<SiteInfo />
                 : selectedButton === PRIVATE_ORGS ? <InventoryDeployedPrivate permissionChanges={permissionChanges} /> 
                 :
                 <InventoryDeployedTable permissionChanges={permissionChanges} /> }
                </Grid>
              </div>
            )
            :
              <Grid item container>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { updatePermission, getInventoryTopWidgetsRegisteredCount, getInventoryTopWidgetKassCount, getInventoryTopWidgetWarehouseCount, getVesselsListingForPrivate
    })(InventoryPage)
  )