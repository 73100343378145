import { connect, useDispatch } from "react-redux";
import close from '../../asset/image/cross.svg';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import './charts.css';
import { displayToastError } from "../../server/request";
import { CHARTS_METRICS, DEFAULT_PAGE_SIZE, EVENT_FILTERS, METRIC_BY_WAN_TYPE } from "../../constants/Constants";
import { getSiteRSSI, getSiteSINR, getSiteDataRate, getSiteLinkStatus, getSiteLatencyJitter, getSiteThroughput, clearSiteCharts, deleteSiteChartSeries, clearSiteChartEvents, getSiteVSATSnr, getSiteVSATTxpower, getSiteVSATPower, getSiteVSATSymbolRate, getSiteInterfaces, ChartsQueryParameters, getSiteClients, getSiteUsage, getSiteSystemUptime } from "../../actions/Users/authenticateCharts";
import { getEdgeEventsForCharts, getEdgeEventsFilter, clearEdgeEvents, getEdgeEventsTable, getEdgeEventsTableCount } from "../../actions/Users/authenticateEdgeEvents";
import _, { cloneDeep } from "lodash"
import moment from "moment";
import { EventsFilterMenu } from "../SiteCharts/EventFilterMenu";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../utils/util";
import './charts.css';
import MultipleSelectChip from "../MultipleSelectChip";
import { CHART_COLORS } from "../../utils/Colors";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_SITE_DATA_RATE, ERROR_GET_SITE_INTERFACES, ERROR_GET_SITE_LATENCY_JITTER, ERROR_GET_SITE_LINK_STATUS, ERROR_GET_SITE_RSSI, ERROR_GET_SITE_SINR } from "../../actions/error_types";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CustomLoader from "../Loader/CustomLoader";
import Chart from '../Charts';
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { Pagination } from "../Pagination";
import { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import TabsComponent from "../NewTabs";

const { PRE, RTT, SINR, RSSI, LINK_STATUS, THROUGHPUT, VSAT_SNR, VSAT_TXPOWER, VSAT_POWER, VSAT_SYMBOL_RATE, CLIENTS, USAGE, SYSTEM_UPTIME } = CHARTS_METRICS;

interface Interval {
    startDate: string;
    endDate: string;
}

interface deviceInterface {
    deviceName: string,
    device_id: string,
    displayInterface: string,
    wanType: string
}

interface locationInterfaces {
    interfaces: deviceInterface[],
    nameToDeviceInterfaceMap: Map<string, deviceInterface>
    deviceIdToNameMap: Map<string, string>
    deviceIdToNameMapForClient: Map<string, string>
    toString(): string[]
    getDeviceInterface(deviceName: string): (deviceInterface | undefined)
    getDeviceName(deviceId: string): (string | undefined)
    getDeviceNameForClient(deviceId: string): (string | undefined)
}

interface EventFilters {
    devices: string[];
    modules: string[];
    levels: string[];
    sources: string[];
    eventsCount?: number
}

const MapCharts = (props: any) => {
    const { getEdgeEventsTable, getEdgeEventsTableCount, open, setOpen, authReducer, errorReducer, newSummaryStartDate, newSummaryEndDate, selectedVessel, selectedVesselName, getEdgeEventsForCharts, getEdgeEventsFilter, clearSiteChartEvents, getSiteRSSI, getSiteSINR, getSiteDataRate, getSiteLinkStatus, getSiteLatencyJitter, getSiteThroughput, clearSiteCharts, deleteSiteChartSeries, getSiteVSATSnr, getSiteVSATTxpower, getSiteVSATPower, getSiteVSATSymbolRate, getSiteInterfaces, getSiteClients, getSiteUsage, getSiteSystemUptime, handleReset, handleChartAreaSelection, location, history,hoveredStart,hoveredEnd  } = props;

    console.log("hovered start", moment(hoveredStart).valueOf(), moment(hoveredEnd).valueOf())
    let diff = (moment(hoveredStart).valueOf() - moment(hoveredEnd).valueOf())
    const [diffinMilli , setDiffinMilli] = useState(0)
    useEffect(() => {
        setDiffinMilli(moment(hoveredStart).valueOf() - moment(hoveredEnd).valueOf())
    },[hoveredStart,hoveredEnd])
    let _q = getDecodeURI(location?.search);
    const [selectedTab, setSelectedTab] = useState('Charts');
    const dispatch = useDispatch()
    const [siteInterfaces, setSiteInterfaces] = useState<locationInterfaces>();
    const [eventFilters, setEventFilters] = useState<EventFilters>();
    const [selectedInterfaces, setSelectedInterfaces] = useState<string[]>([]);
    const [selectedMetrics, setSelectedMetrics] = useState<string[]>([LINK_STATUS, THROUGHPUT]);
    const [metrics, setMetrics] = useState<any>([]);
    const previousSelectedMetrics = useRef(selectedMetrics);
    const previousSelectedInterfaces = useRef(selectedInterfaces);
    const [interval, setChartInterval] = useState<Interval>(() => {
        let now = Date.now();
        return {
            startDate: moment(now).subtract(6, 'hour').toISOString(),
            endDate: moment(now).toISOString()
        }
    })
    const previousInterval = useRef<Interval>()
    const [prevEdgeEventFilterReq, setPrevEdgeEventFilterReq] = useState<any>();
    const [selectedEventFilters, setSelectedEventFilters] = useState<EventFilters>();
    const [Page, setPage] = useState(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1);
    const getInterval = (minutes) => {
        if (minutes <= 60) {
            return "1 minute"
        } else if (minutes <= 1440 && minutes > 60) {
            return "3 minute"
        } else if (minutes > 1440 && minutes <= 10080) {
            return "20 minute"
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    let globalColorCount = 0;
    const [combinedChartOptions, setCombinedChartOption] = useState<any>({});
    const [parameters, setParameters] = useState({});
    const [Limit, setLimit] = useState(DEFAULT_PAGE_SIZE);

    useEffect(() => {
        let parameters = {
            locationId: selectedVessel
        }
        getSiteInterfaces(parameters);
        clearEdgeEvents();

        return () => {
            clearSiteCharts(Object.keys(CHARTS_METRICS).map(key => CHARTS_METRICS[key]));
            clearEdgeEvents();
        }
    }, [selectedVessel])

    useEffect(() => {
        try {
            let _q = getDecodeURI(location?.search);

            // if (_q.startDate && '' !== _q.startDate && _q.startDate > 1) {
            //     let incominStarDate = moment(new Date(parseInt(_q.startDate))).utc();
            //     setSelectedStartDate(incominStarDate);
            // }

            // if (_q.endDate && '' !== _q.endDate && _q.endDate > 1) {
            //     let incominEndDate = moment(new Date(parseInt(_q.endDate))).utc();
            //     setSelectedEndDate(incominEndDate);
            // }
        } catch (error) {
            displayToastError('Error parsing query url');
        }
    }, [location])

    useEffect(() => {
        if (previousInterval.current?.startDate !== interval.startDate || previousInterval.current?.endDate !== interval.endDate) {
            clearSiteCharts(Object.keys(CHARTS_METRICS).map(key => CHARTS_METRICS[key]));
            clearEdgeEvents();
            const _interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3))
            if (!_.isEmpty(selectedInterfaces) && !_.isEmpty(siteInterfaces)) {
                fetchChartData(selectedInterfaces, selectedMetrics, undefined, _interval, selectedTab);
            }
            previousInterval.current = interval;
        }
    }, [interval, hoveredStart , hoveredEnd]);

    useEffect(() => {
        if (newSummaryStartDate.isSameOrBefore(newSummaryEndDate)) {
            setChartInterval({
                startDate: newSummaryStartDate.toISOString(),
                endDate: newSummaryEndDate.toISOString()
            })
        }
        setSelectedBin(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    }, [newSummaryEndDate, newSummaryStartDate, hoveredStart, hoveredEnd])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSiteInterfaces)) {
            const { data } = authReducer.getSiteInterfaces;
            if (data?.rows?.length && data.rows.length > 0) {
                let deviceInterfaces: locationInterfaces = {
                    interfaces: data.rows.map(r => {
                        return { deviceName: r[0], device_id: r[1], displayInterface: r[2], wanType: r[3] }
                    }),
                    toString: function () {
                        return this.interfaces.map(inf => `${inf.deviceName} - ${inf.displayInterface}`)
                    },
                    nameToDeviceInterfaceMap: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[0]} - ${r[2]}`, { deviceName: r[0], device_id: r[1], displayInterface: r[2], wanType: r[3] }));
                        return m;
                    })(),
                    deviceIdToNameMap: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[1]} - ${r[2]}`, `${r[0]} - ${r[2]}`));
                        return m;
                    })(),
                    deviceIdToNameMapForClient: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[1]}`, `${r[0]}`));
                        return m;
                    })(),
                    getDeviceInterface: function (name: string) {
                        return this.nameToDeviceInterfaceMap.get(name);
                    },
                    getDeviceName: function (deviceId: string) {
                        return this.deviceIdToNameMap.get(deviceId);
                    },
                    getDeviceNameForClient: function (deviceId: string) {
                        return this.deviceIdToNameMapForClient.get(deviceId);
                    }
                }
                setSiteInterfaces(deviceInterfaces);
            } else {
                setSiteInterfaces(undefined)
            }
        }
    }, [authReducer.getSiteInterfaces]);

    useEffect(() => {
        if (!_.isEmpty(siteInterfaces)) {
            let di = siteInterfaces?.toString();
            if (di && Array.isArray(di) && di.length > 0) {
                setSelectedInterfaces([di[0]]);
            }
        }
    }, [siteInterfaces])

    useEffect(() => {
        if (siteInterfaces !== undefined) {
            let newInterfaces = _.difference(selectedInterfaces, previousSelectedInterfaces.current);
            let _metrics: any = []
            selectedInterfaces.forEach(intrfc => {
                let di = siteInterfaces.getDeviceInterface(intrfc)
                if (di) {
                    let _m = di?.wanType.includes('_') ? METRIC_BY_WAN_TYPE['vsat'] : METRIC_BY_WAN_TYPE[di?.wanType]
                    _metrics = [..._metrics, ..._m]
                }
            })
            _metrics = Array.from(new Set(_metrics));
            let _selectedMetrics: any = _.intersection(_metrics, selectedMetrics);
            setSelectedMetrics(_selectedMetrics);
            setMetrics(_metrics)
            if (newInterfaces.length > 0) {
                fetchChartData(newInterfaces, _selectedMetrics, undefined, selectedBin, selectedTab);
            }
            let deletedInterfaces = _.difference(previousSelectedInterfaces.current, selectedInterfaces);
            if (deletedInterfaces.length > 0) {
                deletedInterfaces.forEach(i => {
                    let di = siteInterfaces?.getDeviceInterface(i);
                    if (di) {
                        deleteSiteChartSeries(`${di.device_id} - ${di.displayInterface}`);
                    }
                });
            }
        }
        previousSelectedInterfaces.current = selectedInterfaces;
    }, [selectedInterfaces])

    useEffect(() => {
        if (!_.isEmpty(siteInterfaces)) {
            let newMetrics = _.difference(selectedMetrics, previousSelectedMetrics.current);
            if (newMetrics.length > 0) {
                fetchChartData(selectedInterfaces, newMetrics, undefined, selectedBin, selectedTab);
            }
            let deletedMetrics = _.difference(previousSelectedMetrics.current, selectedMetrics);
            if (deletedMetrics.length > 0) {
                clearSiteCharts(deletedMetrics);
            }
        }
        previousSelectedMetrics.current = selectedMetrics;
    }, [selectedMetrics])

    useEffect(() => {
        if (_.isEmpty(selectedEventFilters?.devices) || _.isEmpty(selectedEventFilters?.modules) || _.isEmpty(selectedEventFilters?.levels)) {
            clearSiteChartEvents()
            clearEdgeEvents()
        }
        else if (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) {
            fetchChartData([], [], selectedEventFilters, selectedBin, selectedTab);
        }
    }, [selectedEventFilters])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEventsFilter?.data)) {
            const { data: response } = authReducer.getEdgeEventsFilter;
            if (!_.isEmpty(response) && response.hasOwnProperty('filters')) {
                let filters: string[] | undefined = response?.filters?.data;
                if (filters?.length && filters.length >= 3) {
                    const [devices, modules, levels, sources, eventCount] = filters;
                    let _eventFilter: EventFilters = {
                        devices: devices?.split(','),
                        modules: modules?.split(','),
                        levels: levels?.split(','),
                        sources: sources?.split(','),
                        eventsCount: parseInt(eventCount)
                    };
                    setEventFilters(_eventFilter)

                    let _selectedEventFilters = {
                        devices: _eventFilter.devices.map(d => _eventFilter.devices[d] || d),
                        modules: _eventFilter.modules.filter(m => m !== EVENT_FILTERS._MODULES["WAN Management"]).map(m => EVENT_FILTERS.MODULES[m] || m),
                        levels: _eventFilter.levels.filter(l => l === EVENT_FILTERS._LEVELS["Critical"] || l === EVENT_FILTERS._LEVELS["Warning"]).map(l => EVENT_FILTERS.LEVELS[l] || l),
                        sources: _eventFilter.sources.map(s => EVENT_FILTERS.SOURCES[s] || s)
                    };
                    setSelectedEventFilters(_selectedEventFilters);
                } else {
                    setSelectedEventFilters(undefined)
                    setEventFilters(undefined)
                }
            }
        }
    }, [authReducer.getEdgeEventsFilter])

    useEffect(() => {
        if (!_.isEmpty(selectedVessel) && !_.isEmpty(interval)) {
            let _request: any = {
                locationId: selectedVessel,
                startTime: interval.startDate,
                endTime: interval.endDate,
                deviceIdFilter: "",
                levelFilter: "",
                moduleFilter: "",
                sourceFilter: "",
                search: "",
            }
            if (!_.isEqual(_request, prevEdgeEventFilterReq)) {
                getEdgeEventsFilter(_request);
                setPrevEdgeEventFilterReq(_request);
            }
        }
    }, [selectedVessel, interval])

    const fetchChartData = (interfaces: string[], metrics: string[], eventFilters: EventFilters | undefined = undefined, _selectedBin, tabValue) => {
        if (!_.isEmpty(eventFilters) && !_.isEmpty(eventFilters?.devices) && !_.isEmpty(eventFilters?.modules) && !_.isEmpty(eventFilters?.levels)) {
            let parameters: any = {
                startTime: interval.startDate,
                endTime: interval.endDate,
                locationId: selectedVessel,
                deviceIdFilter: !_.isEmpty(eventFilters?.devices) ? `and device_id IN (${eventFilters?.devices.map(d => `'${d}'`).join()})` : '',
                moduleFilter: !_.isEmpty(eventFilters?.modules) ? `and module IN (${eventFilters?.modules.map(m => `'${EVENT_FILTERS._MODULES[m]}'`).join()})` : '',
                levelFilter: !_.isEmpty(eventFilters?.levels) ? `and level IN (${eventFilters?.levels.map(l => `'${EVENT_FILTERS._LEVELS[l]}'`).join()})` : '',
                sourceFilter: !_.isEmpty(eventFilters?.sources) ? `and source IN (${eventFilters?.sources.map(s => `'${EVENT_FILTERS._SOURCES[s]}'`).join()})` : '',
                search: ""
            }
            if (tabValue == 'Events') {
                getEdgeEventsTable({ ...parameters, offset: ((Page - 1) * 10), pageSize: 10 })
                getEdgeEventsTableCount({ ...parameters, offset: ((Page - 1) * 10), pageSize: 10 })
            } else {
                getEdgeEventsForCharts(parameters)
            }
            setParameters(parameters);
        }
        if (!_.isEmpty(siteInterfaces) && !_.isEmpty(interfaces) && !_.isEmpty(metrics)) {
            let parameters: ChartsQueryParameters = {
                startTime: interval.startDate,
                endTime: interval.endDate,
                locationId: selectedVessel,
                chartInterval: _selectedBin ? _selectedBin : getInterval(Math.floor(((new Date(interval.endDate)).getTime() - (new Date(interval.startDate)).getTime()) / 60e3)),
                device_interface_filter: getSelectedInterfaces(interfaces),
                device_filter: getSelectedInterfacesForClients(interfaces)
            }
            let isClickHouse = true;
            let chartInt = parameters?.chartInterval;
            if (isClickHouse) {
                parameters = {
                    ...parameters,
                    intervalNum: chartInt.split(' ')[0],
                    interval: chartInt.split(' ')[1]
                }
            }
            metrics.forEach(metric => {
                switch (metric) {
                    case LINK_STATUS:
                        getSiteLinkStatus(parameters);
                        break;
                    case RSSI:
                        getSiteRSSI(parameters);
                        break;
                    case PRE:
                        getSiteDataRate(parameters);
                        break;
                    case RTT:
                        getSiteLatencyJitter(parameters);
                        break;
                    case SINR:
                        getSiteSINR(parameters);
                        break;
                    case THROUGHPUT:
                        getSiteThroughput(parameters);
                        break;
                    case VSAT_SNR:
                        getSiteVSATSnr(parameters);
                        break;
                    case VSAT_TXPOWER:
                        getSiteVSATTxpower(parameters);
                        break;
                    case VSAT_POWER:
                        getSiteVSATPower(parameters);
                        break;
                    case VSAT_SYMBOL_RATE:
                        getSiteVSATSymbolRate(parameters);
                        break;
                    case CLIENTS:
                        getSiteClients(parameters);
                        break;
                    case USAGE:
                        getSiteUsage(parameters);
                        break;
                    case SYSTEM_UPTIME:
                        getSiteSystemUptime(parameters);
                        break;
                }
            })
        }
    }

    const getSelectedInterfaces = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            // From: ["EN1K23ABAAA000155 - LTE1", "EN1K23ABAAA000155 - LTE2"]
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `(device_id = '${di.device_id}' AND displayInterface = '${di.displayInterface}')` : null
                })
                .filter(si => si != null)
                .join(" OR ")
            // To:   (device_id = 'EN1K23ABAAA000155' AND displayInterface = 'LTE1') OR (device_id = 'EN1K23ABAAA000155' AND displayInterface = 'LTE2')
            let deviceInterfaceFilter = `WHERE ${toFilterString}`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const getSelectedInterfacesForClients = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `(deviceID = '${di.device_id}')` : null
                })
                .filter(si => si != null)
                .join(" OR ")
            let deviceInterfaceFilter = `AND ${toFilterString}`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const handleEventFiltersChange = (_devices, _modules, _levels, _sources) => {
        if (selectedEventFilters) {
            let _eventFilters = { ...selectedEventFilters }
            let updated = false;
            if (_.isArray(_devices) && !_.isEqual(_devices, selectedEventFilters.devices)) {
                _eventFilters = {
                    ..._eventFilters,
                    devices: _devices
                }
                updated = true;
            }
            if (_.isArray(_modules) && !_.isEqual(_modules, selectedEventFilters.modules)) {
                _eventFilters = {
                    ..._eventFilters,
                    modules: _modules
                }
                updated = true;
            }
            if (_.isArray(_levels) && !_.isEqual(_levels, selectedEventFilters.levels)) {
                _eventFilters = {
                    ..._eventFilters,
                    levels: _levels
                }
                updated = true;
            }
            if (_.isArray(_sources) && !_.isEqual(_sources, selectedEventFilters.sources)) {
                _eventFilters = {
                    ..._eventFilters,
                    sources: _sources
                }
                updated = true;
            }
            if (updated) {
                setSelectedEventFilters(_eventFilters);
                setPage(1);
            }
        }
    }

    const handleInterfaceSelection = (selected) => {
        setSelectedInterfaces(selected);
    }

    const handleMetricSelection = (selected) => {
        setSelectedMetrics(selected)
    }

    const toolTipRSSI = {
        pointFormat: `<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>Provider: <b>{point.provider}</b><br/>Signal Type: <b style="text-transform: uppercase;s">{point.signalType}</b><br/>`,
        valueSuffix: 'dBm'
    }
    const toolTipSINR = {
        ...toolTipRSSI,
        valueSuffix: 'dB'
    }
    const toolTipVSAT_SNR = {
        pointFormat: `<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>Provider: <b>{point.provider}</b><br/>`,
        valueSuffix: 'dB'
    }
    const toolTipVSAT_TXPOWER = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'dBm'
    }
    const toolTipVSAT_POWER = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'dBm'
    }
    const toolTipVSAT_SYMBOL_RATE = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'Bd'
    }

    useEffect(() => {
        let chartOptions = {
            ...defaultChartOptions,
            time: {
                timezone: getTimezoneCity(authReducer?.userTimezone)
            },
            chart: {
                ...defaultChartOptions.chart,
                events: {
                    selection: chartAreaSelectionHandler()
                },
                height: "350px"
            },
            title: {
                ...defaultChartOptions.title,
                text: selectedVesselName,
                floating: false
            },
            series: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) || (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) ? (() => {
                let yAxisCount = 0;
                globalColorCount = 0;
                let performanceMetricSeries: any[] = [];
                let eventSeries: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) {
                    performanceMetricSeries = selectedMetrics.map((metric, i) => {
                        let tmpYAxisCount;
                        switch (metric) {
                            case LINK_STATUS:
                                return authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series ? getSeriesData(authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series, '%', yAxisCount++, 'Probe Success', true) : []
                            case RSSI:
                                return authReducer?.getSiteRSSI?.data?.rssiAvg?.series ? getSeriesData(authReducer?.getSiteRSSI?.data?.rssiAvg?.series, 'dBm', yAxisCount++, RSSI, true, toolTipRSSI) : []
                            case PRE:
                                return authReducer.getSiteDataRate?.data?.rateAvg?.series ? getSeriesData(authReducer.getSiteDataRate?.data?.rateAvg?.series, 'Mbps', yAxisCount++, PRE, true) : []
                            case RTT:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteLatencyJitter?.data?.latency?.series && authReducer?.getSiteLatencyJitter?.data?.jitter?.series ? [
                                    getSeriesData(authReducer?.getSiteLatencyJitter?.data.latency.series, 'ms', tmpYAxisCount, 'Latency', true),
                                    getSeriesData(authReducer?.getSiteLatencyJitter?.data.jitter.series, 'ms', tmpYAxisCount, 'Jitter', true)
                                ].flat() : []
                            case SINR:
                                return authReducer?.getSiteSINR?.data?.sinrAvg?.series ? getSeriesData(authReducer?.getSiteSINR?.data?.sinrAvg?.series, 'db', yAxisCount++, SINR, true, toolTipSINR) : []
                            case THROUGHPUT:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteThroughput?.data?.avgDownSpeed?.series && authReducer?.getSiteThroughput?.data?.avgUpSpeed?.series ? [
                                    getSeriesData(authReducer?.getSiteThroughput?.data.avgDownSpeed.series, 'Mbps', tmpYAxisCount, 'Download Rate', true),
                                    getSeriesData(authReducer?.getSiteThroughput?.data.avgUpSpeed.series, 'Mbps', tmpYAxisCount, 'Upload Rate', true)
                                ].flat() : []
                            case VSAT_SNR:
                                return authReducer?.getSiteVSATSnr?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATSnr?.data?.avg?.series, 'dB', yAxisCount++, VSAT_SNR, true, toolTipVSAT_SNR) : []
                            case VSAT_TXPOWER:
                                return authReducer?.getSiteVSATTxpower?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATTxpower?.data?.avg?.series, 'dBm', yAxisCount++, VSAT_TXPOWER, true, toolTipVSAT_TXPOWER) : []
                            case VSAT_POWER:
                                return authReducer?.getSiteVSATPower?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATPower?.data?.avg?.series, 'dBm', yAxisCount++, VSAT_POWER, true, toolTipVSAT_POWER) : []
                            case VSAT_SYMBOL_RATE:
                                return authReducer?.getSiteVSATSymbolRate?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATSymbolRate?.data?.avg?.series, 'Bd', yAxisCount++, VSAT_SYMBOL_RATE, true, toolTipVSAT_SYMBOL_RATE) : []
                            case CLIENTS:
                                return authReducer?.getSiteClients?.data?.clients?.series ? getSeriesData(authReducer?.getSiteClients?.data?.clients?.series, 'client', yAxisCount++, CLIENTS, true) : []
                            case USAGE:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteUsage?.data?.totalUsage?.series && authReducer?.getSiteUsage?.data?.downUsage?.series && authReducer?.getSiteUsage?.data?.upUsage?.series ? [
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series), getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series)?.['unit'], tmpYAxisCount, 'TotalUsage', true),
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series), getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series)?.['unit'], tmpYAxisCount, 'DownUsage', true),
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series), getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series)?.['unit'], tmpYAxisCount, 'UpUsage', true)
                                ].flat() : []
                            case SYSTEM_UPTIME:
                                return authReducer?.getSiteSystemUptime?.data?.status?.series ? getSeriesDataForUptime(authReducer?.getSiteSystemUptime?.data?.status?.series, '%', yAxisCount++, SYSTEM_UPTIME, true) : []
                        }
                    }).flat();
                }
                if (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) {
                    let seriesData = authReducer?.getEdgeEvents?.data?.rows.map(row => {
                        const [ts, device_id, location_id, name, service, description, module, level, site, metadata] = row;
                        return {
                            x: moment(ts).valueOf(),
                            y: 0,
                            color: EVENT_FILTERS.LEVEL_COLOR[level] ? EVENT_FILTERS.LEVEL_COLOR[level] : 'black',
                            description: `Device ID: <b>${device_id}</b></br> Module: <b>${EVENT_FILTERS.MODULES[module]}</b></br> Level: <b>${EVENT_FILTERS.LEVELS[level]}</b></br><b><i>${description}</i></b>`
                        }
                    });
                    eventSeries = [{
                        ...defaultChartOptions.series,
                        name: 'Event',
                        data: seriesData,
                        marker: {
                            ...defaultChartOptions.series.marker,
                            enabled: true,
                            symbol: 'square'
                        },
                        tooltip: {
                            pointFormat: '{point.description}'
                        },
                        yAxis: yAxisCount++
                    }]
                }
                return cloneDeep([...performanceMetricSeries, ...eventSeries]);
            })() : [],
            xAxis: {
                ...defaultChartOptions.xAxis,
                plotBands: [{
                    color: 'red', // Color value
                    from: diffinMilli > 300000 ? "" : moment(hoveredStart),
                    to: diffinMilli  > 300000 ? "" : moment(hoveredEnd)
                  }],
                plotLines: (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) ? authReducer?.getEdgeEvents?.data?.rows.map(row => {
                    const [ts, device_id, location_id, name, service, description, module, level, site, metadata] = row;
                    return {
                        value: moment(ts).valueOf(),
                        width: 0.5,
                        color: EVENT_FILTERS.LEVEL_COLOR[level] ? EVENT_FILTERS.LEVEL_COLOR[level] : 'black'
                    }
                }) : []
            },
            yAxis: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) || (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) ? (() => {
                let yAxisCount = 0;
                let performanceMetricAxes: any[] = [];
                let eventAxis: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) {
                    performanceMetricAxes = selectedMetrics.map((metric, i) => {
                        switch (metric) {
                            case LINK_STATUS:
                                return authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series ? getYAxis(`${LINK_STATUS} (Probe Success %)`, yAxisCount++) : []
                            case RSSI:
                                return authReducer?.getSiteRSSI?.data?.rssiAvg?.series ? getYAxis(`${RSSI} (dBm)`, yAxisCount++) : []
                            case PRE:
                                return authReducer.getSiteDataRate?.data?.rateAvg?.series ? getYAxis('PRE (Mbps)', yAxisCount++) : []
                            case RTT:
                                return authReducer?.getSiteLatencyJitter?.data?.latency?.series && authReducer?.getSiteLatencyJitter?.data?.jitter?.series ?
                                    getYAxis('RTT (ms)', yAxisCount++) : []
                            case SINR:
                                return authReducer?.getSiteSINR?.data?.sinrAvg?.series ? getYAxis(`${SINR} (db)`, yAxisCount++) : []
                            case THROUGHPUT:
                                return authReducer?.getSiteThroughput?.data?.avgDownSpeed?.series && authReducer?.getSiteThroughput?.data?.avgUpSpeed?.series ?
                                    getYAxis('Throughput (Mbps)', yAxisCount++) : []
                            case VSAT_SNR:
                                return authReducer?.getSiteVSATSnr?.data?.avg?.series ? getYAxis(`${VSAT_SNR} (dB)`, yAxisCount++) : []
                            case VSAT_TXPOWER:
                                return authReducer?.getSiteVSATTxpower?.data?.avg?.series ? getYAxis(`${VSAT_TXPOWER} (dBm)`, yAxisCount++) : []
                            case VSAT_POWER:
                                return authReducer?.getSiteVSATPower?.data?.avg?.series ? getYAxis(`${VSAT_POWER} (dBm)`, yAxisCount++) : []
                            case VSAT_SYMBOL_RATE:
                                return authReducer?.getSiteVSATSymbolRate?.data?.avg?.series ? getYAxis(`${VSAT_SYMBOL_RATE} (Bd)`, yAxisCount++) : []
                            case CLIENTS:
                                return authReducer?.getSiteClients?.data?.clients?.series ? getYAxis(`${CLIENTS}`, yAxisCount++) : []
                            case USAGE:
                                return authReducer?.getSiteUsage?.data?.totalUsage?.series && authReducer?.getSiteUsage?.data?.downUsage?.series && authReducer?.getSiteUsage?.data?.upUsage?.series ? getYAxis(`${USAGE} (GB)`, yAxisCount++) : []
                            case SYSTEM_UPTIME:
                                return authReducer?.getSiteSystemUptime?.data?.status?.series ? getYAxis(`${SYSTEM_UPTIME} (%)`, yAxisCount++) : []
                        }
                    }).flat();
                }
                if (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) {
                    eventAxis = [{ title: null, labels: { enabled: false }, opposite: selectedMetrics.length > 0 }]
                }
                return cloneDeep([...performanceMetricAxes, ...eventAxis]);
            })() : []
        };
        setCombinedChartOption(chartOptions);
    }, [authReducer?.userTimezone, authReducer.getSiteRSSI, authReducer.getSiteDataRate, authReducer.getSiteLatencyJitter, authReducer.getSiteLinkStatus, authReducer.getSiteSINR, authReducer.getSiteThroughput, authReducer.getEdgeEvents, authReducer.getSiteVSATSnr, authReducer.getSiteVSATTxpower, authReducer.getSiteVSATPower, authReducer.getSiteVSATSymbolRate,
    authReducer.getSiteClients, authReducer.getSiteUsage, authReducer.getSiteSystemUptime, hoveredStart , hoveredEnd , diffinMilli
    ])

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteRSSI)) {
            displayToastError(errorReducer.errorGetSiteRSSI.description);
            dispatch({ type: ERROR_GET_SITE_RSSI, payload: {} })
        }
    }, [errorReducer.errorGetSiteRSSI])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteSINR)) {
            displayToastError(errorReducer.errorGetSiteSINR.description);
            dispatch({ type: ERROR_GET_SITE_SINR, payload: {} })
        }
    }, [errorReducer.errorGetSiteSINR])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteDataRate)) {
            displayToastError(errorReducer.errorGetSiteDataRate.description);
            dispatch({ type: ERROR_GET_SITE_DATA_RATE, payload: {} })
        }
    }, [errorReducer.errorGetSiteDataRate])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteLinkStatus)) {
            displayToastError(errorReducer.errorGetSiteLinkStatus.description);
            dispatch({ type: ERROR_GET_SITE_LINK_STATUS, payload: {} })
        }
    }, [errorReducer.errorGetSiteLinkStatus])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteLatencyJitter)) {
            displayToastError(errorReducer.errorGetSiteLatencyJitter.description);
            dispatch({ type: ERROR_GET_SITE_LATENCY_JITTER, payload: {} })
        }
    }, [errorReducer.errorGetSiteLatencyJitter])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteInterfaces)) {
            displayToastError(errorReducer.errorGetSiteInterfaces.description);
            dispatch({ type: ERROR_GET_SITE_INTERFACES, payload: {} })
        }
    }, [errorReducer.errorGetSiteInterfaces])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEvents)) {
            displayToastError(errorReducer.errorGetEdgeEvents.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEvents]);

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleResetZoom = () => {
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        setChartInterval({
            startDate: start.toISOString(),
            endDate: end.toISOString()
        })
        if (handleReset) {
            handleReset()
        }
        setPage(1);
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = '1h';
        doNavigate(params);
    }

    const chartAreaSelectionHandler = () => {
        return (event: any) => {
            let start = new Date(Math.ceil(event.xAxis[0].min)).toISOString();
            let end = new Date(Math.floor(event.xAxis[0].max)).toISOString();
            setChartInterval({
                startDate: start,
                endDate: end
            })
            setPage(1);
            let params = getDecodeURI(location?.search);
            params.startDate = new Date(Math.ceil(event.xAxis[0].min)).valueOf();
            params.endDate = new Date(Math.floor(event.xAxis[0].max)).valueOf();
            params.interval = 'customDates';
            doNavigate(params);
            if (handleChartAreaSelection) {
                handleChartAreaSelection(start, end)
            }
            return false;
        }
    }

    const getRedableBytesValue = (series) => {
        const seriesKeys = Object.keys(series);
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = seriesKeys.some(key => {
            const seriesValue = series[key];
            return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
        })
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        let usage: any = seriesKeys.map(key => {
            let arr: any = [];
            series[key]?.forEach((el, i) => {
                let del = Number(convertBytes(el.y, false));
                arr.push({ x: el.x, y: del, ['key']: key })
            })
            return arr;
        })
        let key = usage?.[0]?.[0]?.['key']
        const usageObj: any = isGBCrossed ? { [key]: usage[0], unit: 'GB' } : { [key]: usage[0], unit: 'MB' }
        return usageObj;
    }

    const getSeriesData = (series: any[], valueUnit: string, yAxisCount: any = 0, metric: any = null, useGlobalColorCount: boolean = false, tooltip: any = {}) => {
        return (() => {
            let selectedInterfaceSet = new Set(selectedInterfaces.map(i => {
                let di = siteInterfaces?.getDeviceInterface(i);
                return di && valueUnit == 'client' ? `${di.device_id}` : di ? `${di.device_id} - ${di.displayInterface}` : ''
            }));
            return Object.keys(series).filter(key => selectedInterfaceSet.has(key)).map((key: string, i: any) => {
                return {
                    ...defaultChartOptions.series,
                    marker: {
                        symbol: 'circle',
                    },
                    name: metric && valueUnit == 'client' ? `${metric} - ${siteInterfaces?.getDeviceNameForClient(key)}` : !metric && valueUnit == 'client' ? `${siteInterfaces?.getDeviceNameForClient(key)}` : metric ? `${metric} - ${siteInterfaces?.getDeviceName(key)}` : siteInterfaces?.getDeviceName(key),
                    data: series[key],
                    color: useGlobalColorCount ? CHART_COLORS[globalColorCount++] : CHART_COLORS[i],
                    tooltip: _.isEmpty(tooltip) ? {
                        valueSuffix: valueUnit != 'client' ? valueUnit : ''
                    } : tooltip,
                    yAxis: yAxisCount,
                    valueUnit: valueUnit
                };
            });
        })()
    }

    const getSeriesDataForUptime = (series: any[], valueUnit: string, yAxisCount: any = 0, metric: any = null, useGlobalColorCount: boolean = false, tooltip: any = {}) => {
        return (() => {
            return Object.keys(series).map((key: string, i: any) => {
                return {
                    ...defaultChartOptions.series,
                    marker: {
                        symbol: 'circle',
                    },
                    name: metric ? `${metric} - ${key}` : key,
                    data: series[key],
                    color: useGlobalColorCount ? CHART_COLORS[globalColorCount++] : CHART_COLORS[i],
                    tooltip: _.isEmpty(tooltip) ? {
                        valueSuffix: valueUnit
                    } : tooltip,
                    yAxis: yAxisCount,
                    valueUnit: valueUnit
                };
            });
        })()
    }

    const getYAxis = (title, i) => {
        return title == `${SYSTEM_UPTIME} (%)` ? {
            title: {
                text: title,
            },
            max: 100,
            type: 'logarithmic',
            lineWidth: 1,
            opposite: i != 0 ? true : false
        } : {
            title: {
                text: title,
            },
            lineWidth: 1,
            opposite: i != 0 ? true : false
        }
    }

    const defaultChartOptions = {
        time: {
            timezone: getTimezoneCity(authReducer?.userTimezone)
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            height: "350px",
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            plotBorderColor: '#e8eaeb',
            plotBorderWidth: 1
        },
        legend: {
            enabled: false,
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 12,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        title: {
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 0.5,
        },
        yAxis: {
            title: {
                text: undefined
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        series: {
            type: 'spline',
            marker: {
                symbol: 'circle',
            },
        },
        showLegendTable: true
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        fetchChartData(selectedInterfaces, selectedMetrics, undefined, value, selectedTab);
    };

    const handleChangePage = (e, value) => {
        const page = value;
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(page);
        doNavigate(params);
        getEdgeEventsTable({ ...parameters, offset: ((page - 1) * Limit), pageSize: Limit })
        getEdgeEventsTableCount({ ...parameters, offset: ((page - 1) * Limit), pageSize: Limit })
    }

    const handleChangeRowsPerPage = (e) => {
        const limit = parseInt(e.target.value);
        const page = 1;
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = limit;
        setPage(1);
        setLimit(limit);
        doNavigate(params);
        getEdgeEventsTable({ ...parameters, offset: ((page - 1) * limit), pageSize: limit })
        getEdgeEventsTableCount({ ...parameters, offset: ((page - 1) * limit), pageSize: limit })
    }

    const handleChangeTab = (tab) => {
        setSelectedTab(tab);
        if (tab == 'Events') {
            fetchChartData([], [], selectedEventFilters, selectedBin, tab)
        } else {
            getEdgeEventsForCharts(parameters)
        }
    }

    const tabs = [
        { tabName: 'Charts', tabValue: 'Charts', pathName: '/vessel-map' },
        { tabName: 'Events', tabValue: 'Events', pathName: '/vessel-map' }
    ]

    const doNavigateTab = (pathname:any, tabName:any) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        if (currentParams.ouName) {
          params['ouName'] = currentParams.ouName;
        }
        if (currentParams.ouId) {
          params['ouId'] = currentParams.ouId;
        }
        if (currentParams.k4Ids) {
          params['k4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
          params['sideNav'] = currentParams.sideNav
        }
        if(currentParams.interval) {
          params['interval'] = currentParams.interval
        }
        if(currentParams.startDate && currentParams.startDate > 1) {
          params['startDate'] = currentParams.startDate
        }
        if(currentParams.endDate && currentParams.endDate > 1) {
          params['endDate'] = currentParams.endDate
        }
        if(currentParams.dataSource) {
          params['dataSource'] = currentParams.dataSource
        }
        if(currentParams.layer) {
        params['layer'] = currentParams.layer
        }
        setSelectedTab(tabName);
        history.push({ pathname, search: getEncodedURI(params) });
    }

    return (
        <div className="Form menuScroll maps-chart-div">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                // PaperComponent={DraggablePaperComponent}
                sx={{ borderRadius: '8px', position:'absolute', top:'-60px', right:'-10px', display: 'block',
                    '& .MuiDialog-container': {
                        height: 'auto',
                        // display: 'block',
                        width: 'calc(100% - 6%)'
                    }
                 }}
                className='maps-charts-dialogue menuScroll '
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>SDWAN</span>
                        <img src={close} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div id="maps-sdwan-charts-btns">
                                <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
                            </div>
                            <div className="maps-charts-filters-container">
                                {eventFilters && selectedTab == 'Events' ? <EventsFilterMenu
                                    devices={eventFilters?.devices}
                                    modules={eventFilters?.modules.map(value => EVENT_FILTERS.MODULES[value] || value)}
                                    levels={eventFilters?.levels.map(value => EVENT_FILTERS.LEVELS[value] || value)}
                                    sources={eventFilters?.sources.map(value => EVENT_FILTERS.SOURCES[value] || value)}
                                    handleChange={handleEventFiltersChange}
                                    defaultSelectionDevices={selectedEventFilters?.devices}
                                    defaultSelectionModules={selectedEventFilters?.modules}
                                    defaultSelectionLevels={selectedEventFilters?.levels}
                                    defaultSelectionSources={selectedEventFilters?.sources}
                                /> :
                                    <div className="charts-filters-topbar">
                                        <div>
                                            {siteInterfaces && selectedInterfaces && <MultipleSelectChip defaultSelection={selectedInterfaces} handleSelection={handleInterfaceSelection} values={siteInterfaces.toString()} name={'Interfaces'} sx={{ maxWidth: "177px", width: "177px" }} />}
                                            <MultipleSelectChip defaultSelection={selectedMetrics} handleSelection={handleMetricSelection} values={metrics} name={'Metrics'} sx={{ maxWidth: "177px", width: "177px" }} />
                                        </div>
                                        <div>
                                            <FormControl variant="standard" className='padding-left-25px'>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedBin}
                                                    onChange={handleBinChange}
                                                >
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1 && <MenuItem value={'1 minute'}>1m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 3 && <MenuItem value={'3 minute'}>3m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minute'}>10m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 20 && <MenuItem value={'20 minute'}>20m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                                </Select>
                                            </FormControl>
                                            <div className="mapCharts__filters-reset-zoom padding-right-22px">
                                                <FormControlLabel
                                                    value="Reset Zoom"
                                                    control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                                                    label="Reset Zoom"
                                                    labelPlacement="start"
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            {selectedTab == 'Charts' ? <div className="siteCharts__charts-container-freeFormTool map-charts-highchart">
                                {!_.isEmpty(combinedChartOptions) ? <Chart hideLegendTable={true} chartOptions={combinedChartOptions} immutable={true} updateArgs={[true, false, true]} page={Page} setPage={setPage} enableEventsTable={false} uptimeChart={SYSTEM_UPTIME ? true : ''} /> : null}
                            </div> : <EventsTable Page={Page} setPage={setPage} authReducer={authReducer} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} Limit={Limit} />}
                            {
                                authReducer.loading || authReducer.siteChartsSiteInterfacesLoading || authReducer.siteChartsRSSILoading || authReducer.siteChartsSINRLoading || authReducer.siteChartsLinkStatusLoading || authReducer.siteChartsLatencyJitterLoading || authReducer.siteChartsThroughputLoading ||
                                    authReducer.siteChartsVSATSnrLoading || authReducer.siteChartsVSATyTXPowerLoading || authReducer.siteChartsVSATPowerLoading || authReducer.siteChartsVSATSymbolRateLoading || authReducer.siteChartsSiteDataRateLoading || authReducer.getEdgeEventsTableLoading || authReducer.siteChartsSiteClientsLoading ||
                                    authReducer.siteChartsSiteUsageLoading || authReducer.siteChartsSiteSystemUptimeLoading || authReducer.getEdgeEventsTableCountLoading ?
                                    <CustomLoader
                                        showLoader={true}
                                        loadingText={"Fetching data please wait..."}
                                    />
                                    : null
                            }
                        </div>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

function DraggablePaperComponent(props: PaperProps) {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setPosition({ x, y });
    }

    return (
        <Draggable position={position} onStop={handleDrag} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const EventsTable = (props: any) => {
    const { authReducer, Page, handleChangePage, handleChangeRowsPerPage, Limit } = props;
    const [edgeTable, setEdgeTable] = useState<any>([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEventsTable)) {
            const data = authReducer?.getEdgeEventsTable?.hasOwnProperty('data') ? authReducer?.getEdgeEventsTable?.data : {};
            if (data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const edgeData: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    edgeData.push(row);
                }
                setEdgeTable(edgeData);
            }
        } else {
            setEdgeTable([]);
        }
        if (!_.isEmpty(authReducer.getEdgeEventsTableCount)) {
            const data = authReducer?.getEdgeEventsTableCount.hasOwnProperty('data') ? authReducer?.getEdgeEventsTableCount?.data?.filters?.data[4] : 0;
            setCount(data);
        } else {
            setCount(0)
        }
    }, [authReducer.getEdgeEventsTable, authReducer.getEdgeEventsTableCount])

    const rowsOption = [10, 15, 25, 50, 100];

    return (
        <Grid className="chartsEventTableContainer">
            <Grid className="chartsEventTable">
                <Paper className="pagination-position usage-pagination">
                    <Pagination
                        rowsPerPageOptions={rowsOption}
                        count={count}
                        rowsPerPage={Limit}
                        page={Page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard chartEvent-FleetDashboard">
                    <Table aria-label="simple table">
                        <TableHead className="usageReports-tableHead">
                            <TableRow className="UsageReports-tableRow maps-charts-fleetDataTableRow">
                                <TableCell className="usage-fleetDataTable">TIMESTAMP</TableCell>
                                <TableCell className="usage-fleetDataTable">DID</TableCell>
                                <TableCell className="usage-fleetDataTable">SOURCE</TableCell>
                                <TableCell className="usage-fleetDataTable">LEVEL</TableCell>
                                <TableCell className="usage-fleetDataTable">MODULE</TableCell>
                                <TableCell className="usage-fleetDataTable">DESCRIPTION</TableCell>
                                <TableCell className="usage-fleetDataTable">METADATA</TableCell>
                            </TableRow>
                        </TableHead>
                        {0 != edgeTable.length ? (
                            <TableBody className="tableBody usage-tableBody">
                                {
                                    edgeTable && edgeTable.map((row, i) =>
                                        <TableRow className="usageData-tabelRow chartEvent-tableRow">
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{convertDateTimeIntoTimezone(row['ts'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['device_id']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell chartEvent-tablecell-source">{row['source']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['level']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['module']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['description']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">
                                                <JsonDataTooltip data={row['metadata']} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ) : <TableBody>
                            <TableRow>
                                <TableCell colSpan={7} className="noDataAvailableCss" align="center">No data available</TableCell>
                            </TableRow>
                        </TableBody>
                        }
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getSiteRSSI,
        getSiteSINR,
        getSiteDataRate,
        getSiteLinkStatus,
        getSiteLatencyJitter,
        getSiteThroughput,
        getSiteVSATSnr,
        getSiteVSATTxpower,
        getSiteVSATPower,
        getSiteVSATSymbolRate,
        clearSiteCharts,
        deleteSiteChartSeries,
        getEdgeEventsForCharts,
        getEdgeEventsFilter,
        clearSiteChartEvents,
        clearEdgeEvents,
        getSiteInterfaces,
        getSiteClients,
        getSiteUsage,
        getSiteSystemUptime,
        getEdgeEventsTable,
        getEdgeEventsTableCount
    })(MapCharts)
);