import React, { useEffect, useState } from 'react';
import { Autocomplete, Divider, Button, Dialog, Grid, TextField, IconButton, Tooltip, Typography, DialogTitle, DialogContent, DialogActions, Chip, Paper, Box, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItem, ListItemText } from "@mui/material";

import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateServiceLineDetails, ACTION_TYPES } from '../slice';
import _ from 'lodash';

const ServiceLineDetails = (props) => {
    const { serviceLineToUpdate, updateServiceLineDetails, changeSLName, updateSLDetails } = props
    const dispatch = useDispatch();
    const [isEditingServiceLine, setIsEditingServiceLine] = useState(false);
    const [isEditingHomeAddress, setIsEditingHomeAddress] = useState(false);
    const [serviceLineName, setServiceLineName] = useState('');
    const [originalServiceLineName, setOriginalServiceLineName] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [originalHomeAddress, setOriginalHomeAddress] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [originalEmails, setOriginalEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const [addressData, setAddressData] = useState({
        administrativeAreaCode: ``,
        regionCode: '',
        formattedAddress: ``,
        addressLines: [],
        latitude: '',
        longitude: ''
    });

    const formFields = [
        {
            label: "Formatted Address",
            placeholder: "ex: 1 Rocket Road",
            value: addressData.formattedAddress,
            onChange: (e) => handleAddressChange('formattedAddress', e.target.value),
            xs: 12,
        },
        {
            label: "Address Lines",
            placeholder: "Address lines",
            value: addressData.addressLines,
            onChange: (e) => handleAddressChange('addressLines', [e.target.value]),
            xs: 12,
        },
        {
            label: "Region Code",
            placeholder: "Region ex: US",
            value: addressData.regionCode,
            onChange: (e) => handleAddressChange('regionCode', e.target.value),
            xs: 6,
        },
        {
            label: "Administrative Area Code",
            placeholder: "State ex: CA",
            value: addressData.administrativeAreaCode,
            onChange: (e) => handleAddressChange('administrativeAreaCode', e.target.value),
            xs: 6,
        },
        {
            label: "Latitude",
            placeholder: "Latitude",
            value: addressData.latitude,
            onChange: (e) => handleAddressChange('latitude', e.target.value),
            xs: 6,
        },
        {
            label: "Longitude",
            placeholder: "Longitude",
            value: addressData.longitude,
            onChange: (e) => handleAddressChange('longitude', e.target.value),
            xs: 6,
        },
    ];

    const handleAddressChange = (field, value) => {
        setAddressData({
            ...addressData,
            [field]: value,
        });
    };

    useEffect(() => {
        setServiceLineName(serviceLineToUpdate.serviceLineName);
        setOriginalServiceLineName(serviceLineToUpdate.serviceLineName);
        setEmails(serviceLineToUpdate.emailIds || []);
        setOriginalEmails(serviceLineToUpdate.emailIds || []);
        setHomeAddress(serviceLineToUpdate.address?.formattedAddress);
        setOriginalHomeAddress(serviceLineToUpdate.address?.formattedAddress);
    }, [serviceLineToUpdate]);

    const handleEditToggle = (type) => {
        if (type === 'serviceLine') {
            setIsEditingServiceLine(!isEditingServiceLine);
        } else if (type === 'homeAddress') {

            setAddressData({
                formattedAddress: serviceLineToUpdate.address?.formattedAddress || '',
                addressLines: serviceLineToUpdate.address?.addressLines || [],
                regionCode: serviceLineToUpdate.address?.regionCode || '',
                administrativeAreaCode: serviceLineToUpdate.address?.administrativeAreaCode || '',
                latitude: serviceLineToUpdate.address?.latitude || '',
                longitude: serviceLineToUpdate.address?.longitude || ''
            });
            setDialogOpen(true);
        }
    };

    const handleCancelEdit = (type) => {
        if (type === 'serviceLine') {
            setServiceLineName(originalServiceLineName);
            setIsEditingServiceLine(false);
        } else if (type === 'homeAddress') {
            setHomeAddress(originalHomeAddress);
            setIsEditingHomeAddress(false);
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails((prev) => prev.filter((email: any) => email?.email !== emailToDelete.email));
    };

    const handleAddEmail = (e) => {
        if (e.key === 'Enter' && emailInput) {
            setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
            // setEmails([...emails, { email: emailInput, name: '' }]);
            setEmailInput('');
        }
    };

    const handleSave = async () => {
        const updatedAddress = {
            addressReferenceId: serviceLineToUpdate.address?.addressReferenceId,
            formattedAddress: addressData.formattedAddress,
            addressLines: addressData.addressLines,
            regionCode: addressData.regionCode,
            administrativeAreaCode: addressData.administrativeAreaCode,
            latitude: addressData.latitude,
            longitude: addressData.longitude
        };

        let updatePayload: any = {

        };

        if (serviceLineName !== originalServiceLineName) {
            updatePayload.serviceLineName = serviceLineName;
        }
        if (dialogOpen) {
            updatePayload.address = updatedAddress;
        }
        if (JSON.stringify(emails) !== JSON.stringify(originalEmails)) {
            updatePayload.emailIds = emails;
        }

        updatePayload.organizationId = serviceLineToUpdate.accountDpId;
        updatePayload.serviceLineNumber = serviceLineToUpdate.serviceLineNumber;
        updatePayload.accountNumber = serviceLineToUpdate.accountNumber;

        try {
            updateServiceLineDetails(updatePayload)

        } catch (e) {
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
        }
    };

    useEffect(() => {
        if (!_.isEmpty(updateSLDetails)) {
            setOriginalServiceLineName(serviceLineName);
            setOriginalHomeAddress(homeAddress);
            setOriginalEmails(emails);
            setIsEditingServiceLine(false);
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
            changeSLName(serviceLineName)
            dispatch({ type: ACTION_TYPES.UPDATE_SERVICE_LINE_DETAILS, payload: {} })
        }
        else {
            setIsEditingHomeAddress(false);
            setDialogOpen(false)
        }
    }, [updateSLDetails])

    return (
        <div className="serviceLineDetails-container">
            <Box className="starlink-serviceline-details-container">
                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Account Number:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.accountNumber}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Service Line Number:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.serviceLineNumber}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Service Line Name:</Box>
                    {isEditingServiceLine ? (
                        <>
                            <TextField
                                size="small"
                                value={serviceLineName}
                                onChange={(e) => setServiceLineName(e.target.value)}
                                className="starlink-serviceline-details-value"
                            />
                            <Tooltip title="Save">
                                <span>
                                    <IconButton
                                        //   color="primary"
                                        onClick={handleSave}
                                        className="starlink-serviceline-details-save-button"
                                        disabled={serviceLineName === originalServiceLineName}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={() => handleCancelEdit('serviceLine')} className="starlink-serviceline-details-cancel-button">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Box className="starlink-serviceline-details-value">{serviceLineName}</Box>
                            <IconButton onClick={() => handleEditToggle('serviceLine')} className="starlink-serviceline-details-edit-button padding-left-right">
                                <EditIcon />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Home Address:</Box>
                    {isEditingHomeAddress ? (
                        <>
                            <TextField
                                size="small"
                                value={homeAddress}
                                onChange={(e) => setHomeAddress(e.target.value)}
                                className="starlink-serviceline-details-value"
                            />
                            <Tooltip title="Save">
                                <span>
                                    <IconButton
                                        onClick={handleSave}
                                        className="starlink-serviceline-details-save-button"
                                        disabled={homeAddress === originalHomeAddress}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={() => handleCancelEdit('homeAddress')} className="starlink-serviceline-details-cancel-button ">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Box className="starlink-serviceline-details-value">{homeAddress}</Box>
                            <IconButton onClick={() => handleEditToggle('homeAddress')} className="starlink-serviceline-details-edit-button padding-left-right">
                                <EditIcon />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Kit Serial:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.userTerminals}</Box>
                </Box>

                <Box className="starlink-serviceline-details-row">
                    <Box className="starlink-serviceline-details-heading">Current plan:</Box>
                    <Box className="starlink-serviceline-details-value">{serviceLineToUpdate.servicePlan}</Box>
                </Box>
            </Box>



            <Typography fontWeight={"550"} component="div" >Email Ids:</Typography>
            <Grid item xs={12}>
                <Box>
                    <Paper
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: '8px',
                            borderRadius: '10px',
                            border: '1px solid #c2d3eb',
                            boxShadow: 'none',
                        }}
                    >
                        {emails.map((email, index) => (
                            <Chip
                                key={index}
                                label={email?.email}
                                onDelete={() => handleDeleteEmail(email)}
                                sx={{ margin: '4px' }}
                            />
                        ))}
                        <TextField
                            size="small"
                            fullWidth
                            variant="standard"
                            error={!!error}
                            placeholder="Press Enter to add email"
                            helperText={error}
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleAddEmail}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    padding: '0 4px',
                                },
                            }}
                            sx={{
                                width: 'auto',
                                flexGrow: 1,
                                minWidth: '120px',
                                '& .MuiInputBase-root': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '8px 4px',
                                    flex: 1,
                                },
                            }}
                        />
                    </Paper>
                </Box>
                <Typography variant="caption" color="textSecondary" sx={{ marginTop: '4px', marginLeft: '8px' }}>
                    Quota Kontrol Notifications shall be sent to this email.
                </Typography>

                {/* Show Save Button Only When Emails Change */}
                {JSON.stringify(emails) !== JSON.stringify(originalEmails) && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button variant="contained" onClick={handleSave}> Save </Button>
                    </Box>
                )}
            </Grid>


            <Dialog
                PaperProps={{
                    sx: {
                        width: '500px',
                        maxWidth: '500px',
                    },
                }}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>Enter Address</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {formFields.map((field, index) => (
                            <Grid item xs={field.xs} key={index}>
                                <Typography variant="subtitle1">{field.label}</Typography>
                                <TextField
                                    fullWidth
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={
                            addressData.formattedAddress === '' ||
                            addressData.administrativeAreaCode === '' ||
                            addressData.regionCode === '' ||
                            addressData.latitude === '' ||
                            addressData.longitude === ''
                        }
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>


    );
};

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    updateSLDetails: state.starlinkAdmin.updateServiceLineDetail,
    authReducer: state.authReducer
});

export default withRouter(
    connect(mapStateToProps, { updateServiceLineDetails })(ServiceLineDetails)
);

