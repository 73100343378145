import { useEffect, useState } from "react";
import { InputAdornment, Button, Checkbox, Dialog, FormControl, Grid, TextField, Typography, IconButton, MenuItem, Select, Chip, Box } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Paper from '@mui/material/Paper';
import _ from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { createParentPool, updateParentPool } from "../slice";
import moment from "moment"
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';


function PooledDialog(props) {

    const { starlinkAdmin, open, onClose, authReducer, selectedAccount, accountCredential, createParentPool, updateParentPool, accountDp } = props;
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [error, setError] = useState('');
    const [entries, setEntries] = useState([{ usagePercentage: '70', type: 'sendNotification' }]);

    const handleValueChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleAddEntry = () => {
        setEntries([...entries, { usagePercentage: '', type: 'sendNotification' }]);
    };

    const [formData, setFormData] = useState<any>({
        poolName: '',
        poolSize: '',
        poolUnit: 'tb',
        startDate: null,
        endDate: null,
        isRecurring: true,
        poolInfo: ''
    });

    const handleInputChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleClose = (refresh: boolean = false) => (event: {}, reason: "backdropClick" | "escapeKeyDown"): void => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        onClose(refresh);
    }

    useEffect(() => {

    }, [starlinkAdmin.creatingServiceAccount, starlinkAdmin.updatingServiceAccount, starlinkAdmin.deletingServiceAccount]);

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };

    const handleUpdatePool = async () => {
        let params = {
            parentPoolId: selectedAccount?.parentPoolId,
            dpId: accountDp ? accountDp : "",
            accountNumber: selectedAccount?.accountNumber,
            credentialId: accountCredential,
            poolName: selectedAccount?.accountName,
            poolSize: formData?.poolSize,
            unit: formData?.poolUnit,
            startDate: formData?.startDate,
            endDate: formData?.endDate,
            isRecurring: formData?.isRecurring,
            poolInfo: formData?.poolInfo,
            notificationMethods: ["email"],
            quotaActions: entries,
            usersToNotify: emails
        }

        let res: any = await updateParentPool(params);

        if (res?.success) {
            toast.success(`${selectedAccount?.accountName} updated successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {

        }
        onClose(false);
        clearData()
    }


    const handleAddPool = async () => {
        let params = {
            dpId: accountDp ? accountDp : "",
            accountNumber: selectedAccount?.accountNumber,
            credentialId: accountCredential,
            poolName: selectedAccount?.accountName,
            poolSize: formData?.poolSize,
            unit: formData?.poolUnit,
            startDate: formData?.startDate,
            endDate: formData?.endDate,
            isRecurring: formData?.isRecurring,
            poolInfo: formData?.poolInfo,
            notificationMethods: ["email"],
            quotaActions: entries,
            usersToNotify: emails
        }

        let res: any = await createParentPool(params);

        if (res?.success) {
            toast.success(`${selectedAccount?.accountName} created successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {

        }


        onClose(false);
        clearData()
    }

    const clearData = () => {
        setEmails([])
        setEntries([{ usagePercentage: '70', type: 'sendNotification' }])
        setFormData({
            poolName: '',
            poolSize: '',
            poolUnit: 'tb',
            startDate: null,
            endDate: null,
            isRecurring: true,
            poolInfo: ''
        })
    }

    useEffect(() => {
        clearData()
        if (selectedAccount?.isPooledAccount) {
            setFormData({
                poolName: selectedAccount?.accountName,
                poolSize: selectedAccount?.poolSize,
                poolUnit: selectedAccount?.unit,
                startDate: moment(selectedAccount?.startDate),
                endDate: moment(selectedAccount?.endDate),
                isRecurring: selectedAccount?.isRecurring,
                poolInfo: selectedAccount?.poolInfo
            })
            setEmails(selectedAccount?.quota?.usersToNotify)
            setEntries(selectedAccount?.quota?.quotaActions?.map((q) => { return { usagePercentage: q?.usagePercentage, type: q?.type } }))
        }

    }, [selectedAccount, open])

    const [chips, setChips] = useState<any>([]);
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setChips([...chips, inputValue.trim()]);
            setInputValue('');
            event.preventDefault();
        }
    };

    const handleDelete = (chipToDelete) => () => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Dialog open={open} onClose={handleClose(true)} aria-labelledby="service_account--dialog" maxWidth="lg" fullWidth>
            <Paper elevation={1} classes={{ root: 'starlink--base--padding_1x' }}>

                <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div" align="center" style={{ fontWeight: 'bold', color: "#424141" }}>
                            Pooled Account Info
                        </Typography>
                    </Grid>
                    <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography fontSize={"0.8rem"}> Note: Configure Starlink Pool Account Size and Duration and optionally Quota Kontrol.</Typography>
                    </Grid>

                    <Grid>
                        <>
                            <Grid container spacing={2} alignItems="center" mt={1}>
                                <Grid item xs={2.5}>
                                    <Typography variant="subtitle1">Pool Name</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="subtitle1">Pool Size</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle1">Unit</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="subtitle1">Start Date</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="subtitle1">End Date</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle1">Recurring</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={2.5}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={selectedAccount?.accountName}
                                        disabled={true}
                                        // onChange={(e) => handleInputChange('poolName', e.target.value)}
                                        placeholder="For eg. Main pool"
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        value={formData.poolSize}
                                        onChange={(e) => handleInputChange('poolSize', e.target.value)}
                                        placeholder="500"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Select
                                        fullWidth
                                        size="small"
                                        value={formData.poolUnit}
                                        onChange={(e) => handleInputChange('poolUnit', e.target.value)}

                                    >
                                        <MenuItem value="gb">GB</MenuItem>
                                        <MenuItem value="tb">TB</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            value={formData.startDate}
                                            onChange={(newValue) => handleInputChange('startDate', newValue)}

                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            height: '40px',
                                                            padding: '0 14px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            lineHeight: 'normal',
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>

                                </Grid>
                                <Grid item xs={2.5}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            value={formData.endDate}
                                            onChange={(newValue) => handleInputChange('endDate', newValue)}

                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            height: '40px',
                                                            padding: '0 14px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            lineHeight: 'normal',
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={1}>
                                    <Checkbox
                                        checked={formData.isRecurring}
                                        onChange={(e) => handleInputChange('isRecurring', e.target.checked)}
                                    />
                                </Grid>
                                <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                                    <Grid item xs={0.75}></Grid>
                                    <Grid item xs={1}>
                                        <Typography>
                                            Pool Info
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={0.75}></Grid>
                                    <Grid item xs={8.5}>
                                        <TextField
                                            multiline
                                            rows={3}
                                            fullWidth
                                            value={formData?.poolInfo}
                                            onChange={(e) => handleInputChange('poolInfo', e.target.value)}
                                            size="small"
                                            placeholder="For eg. Some Description about pool"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                    {entries.map((entry, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            key={index}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                        >
                                            <Grid item xs={0.90}></Grid>
                                            <Grid item xs={1}>
                                                <Typography>Quota Action </Typography>
                                            </Grid>
                                            <Grid item xs={0.75}></Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        label="% used"
                                                        variant="outlined"
                                                        value={entry.usagePercentage}
                                                        type="number"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value) && (value === "" || (parseFloat(value) >= 0 ))) {
                                                                handleValueChange(index, 'usagePercentage', value);
                                                            }
                                                        }}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                            min: "0",
                                                            max: "100"
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={0.25}></Grid>
                                            <Grid item xs={4}>
                                                <Select
                                                    label="Action"
                                                    fullWidth
                                                    size="small"
                                                    value={entry.type}
                                                    onChange={(e) => handleValueChange(index, 'type', e.target.value)}
                                                >
                                                    <MenuItem value="sendNotification">Send Notification</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={0.5} display="flex" alignItems="center">
                                                {/* Delete Icon Button */}
                                                {entries.length > 1 && (
                                                    <IconButton
                                                        onClick={() => handleRemoveEntry(index)}
                                                        color="secondary"
                                                    >
                                                        <DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                                    </IconButton>
                                                )}
                                                {index === entries.length - 1 && (
                                                    <IconButton onClick={handleAddEntry} color="primary">
                                                        <AddCircleOutlineIcon />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>


                                <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                                    <Grid item xs={0.75}></Grid>
                                    <Grid item xs={1}>
                                        <Typography>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={0.75}></Grid>
                                    <Grid item xs={6.4}>
                                        <Box>
                                            <Paper
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    padding: '8px',
                                                    borderRadius: '10px',
                                                    border: '1px solid #c2d3eb',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                {emails.map((email, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={email?.email}
                                                        onDelete={() => handleDeleteEmail(email)}
                                                        sx={{ margin: '4px' }}
                                                    />
                                                ))}
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    error={!!error}
                                                    placeholder="Press Enter to add email"
                                                    helperText={error}
                                                    value={emailInput}
                                                    onChange={(e) => setEmailInput(e.target.value)}
                                                    onKeyDown={handleAddEmail}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            padding: '0 4px',
                                                        },
                                                    }}
                                                    sx={{
                                                        width: 'auto',
                                                        flexGrow: 1,
                                                        minWidth: '120px',
                                                        '& .MuiInputBase-root': {
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 4px',
                                                            flex: 1,
                                                        },
                                                    }}
                                                />
                                            </Paper>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </Grid>

                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                        <Button variant="outlined" className='starlink--button--cancel'
                            onClick={() => {
                                onClose(false);
                                clearData()
                            }}>Cancel</Button>
                        <Button variant="contained" className='starlink--button_contained--primary'
                            onClick={() => { selectedAccount?.isPooledAccount ? handleUpdatePool() : handleAddPool() }}
                            disabled={formData.poolSize === '' ||
                                formData.startDate === null ||
                                formData.endDate === null ||
                                formData.poolInfo === ''} >
                            {selectedAccount?.isPooledAccount ? "Update" : "Create"}
                        </Button>
                    </Grid>
                </Grid>

            </Paper>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkCloud: state.starlinkCloud,
    starlinkAdmin: state.starlinkAdmin,
    serviceLines: state.starlinkAdmin.allServiceLines,
});

export default withRouter(
    connect(mapStateToProps, {
        createParentPool,
        updateParentPool,
    })(PooledDialog)
);
