import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, Menu } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, updateTimePeriod } from "../../utils/util";
import { SIDE_MENU_ITEM_CYBER, SIDE_MENU_ITEM_TRENDS } from "../../utils/constants";
import { BC_DASHBOARD_VESSELS, BC_DASHBOARD_ALERTS, BC_DASHBOARD_LOCATIONS } from "../../constants/Constants";
import { INTERVALS } from "../../constants/Constants";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters"
import NotAuthorized from "../NotAuthorized"
import ThreatCard from "../../components/CardDetails/ThreatCard";
import { updatePermission } from "../../actions/Users/authenticate";
import _ from "lodash";
import { clearSiteDashboard } from "../../actions/Users/authenticateDashboard";
import "./ThreatDashboard.css"
import Tabs from "../../components/Tabs";
import ThreatChartsComponent from "./ThreatsComponent";
import Top10ThreatTable from "./ThreatsComponent/Top10ThreatTable";
import AllThreatTable from "./ThreatsComponent/AllThreatTable";
import UserThreatTable from "./ThreatsComponent/UserThreatDetails";
import TabsComponent from "../../components/NewTabs";
import ThreatChips from "./ThreatsComponent/ThreatChips";
import TuneIcon from '@mui/icons-material/Tune';
import { SET_THREAT_FILTERS } from "../../actions/types";
import ThreatFilters from "./ThreatsComponent/threatFilters";

const ThreatDashboard = (props) => {
  const { authReducer, match, location, updatePermission, history ,threatTopWidgetLoading,
    threatTopCrossBarDataLoading,
    topThreatServerityDataLoading,
    theatBySeverityBarChartLoading,
    top10ThreatTableDataLoading,
    userThreatTableDataLoading,
    allThreatTableDataLoading,allThreatSiteListLoading, allTheratDeviceListLoading ,threatFilters,
    threatUniqueThreatNameListLoading,threatUniqueClassListLoading,threatUniqueIpListLoading,threatUniqueDeviceListLoading
  } = props;

  const _q = getDecodeURI(location?.search);
  const setBreadcrumbInfo: any = React.useRef();
  const [search, setSearch] = useState<string>("");
  // const [filterBy, setFilterBy] = useState<string>("");
  const [interval, setInterval] = useState(INTERVALS[0]);
  const [loading, setLoading] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [timePeriod, setTimePeriod] = useState({});
  const [selectedTab, setSelectedTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : "topthreats");
  const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
  const [filterPopup, setFilterPopup] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    isCurrentAlert: true,
    Alerting: true,
    Normal: true
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // Default values that you want to pass to authReducer
    const defaultAuthValues = {
        ip: _q?.ip ? _q?.ip : "",
        threatName: _q?.threatName ? _q?.threatName : "",
        threatClass: _q?.threatClass ? _q?.threatClass :'',
        deviceName: _q?.deviceName ?  _q?.deviceName  : ""
    };
    dispatch({
        type: SET_THREAT_FILTERS,
        payload: defaultAuthValues
    });
}, [dispatch]);


  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];
  let breadcrumbInfo = BC_DASHBOARD_VESSELS;

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_CYBER !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_CYBER };
}

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    const params = getDecodeURI(location?.search);
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) });
    
    return () => {
      clearSiteDashboard();
    }
  }, []);

  useEffect(() => {
    setLoading(  threatTopWidgetLoading || threatTopCrossBarDataLoading || topThreatServerityDataLoading || theatBySeverityBarChartLoading ||
      top10ThreatTableDataLoading || allThreatTableDataLoading || authReducer?.loading || userThreatTableDataLoading || allThreatSiteListLoading || allTheratDeviceListLoading ||
      threatUniqueThreatNameListLoading || threatUniqueClassListLoading || threatUniqueIpListLoading || threatUniqueDeviceListLoading
    );
  }, [ threatTopWidgetLoading,threatTopCrossBarDataLoading,topThreatServerityDataLoading,theatBySeverityBarChartLoading,
    top10ThreatTableDataLoading,allThreatTableDataLoading, userThreatTableDataLoading, authReducer?.loading ,allThreatSiteListLoading 
    , allTheratDeviceListLoading,threatUniqueClassListLoading,threatUniqueIpListLoading,threatUniqueDeviceListLoading]);

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
            return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
        vessels = filteredVessels?.filter((el) => el?.id !== "");
    } else {
        vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer.filteredVessels])


  const handleSiteClick = (row) => {
    let params: any = getDecodeURI(location?.search);
    row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    delete params.search;
    doNavigate(params);
  }

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }
  
  const tabs = [
    { tabName: 'Top Threats', tabValue: 'topthreats', pathName: '/threats' },
    { tabName: 'EndPoint', tabValue: 'endpoint', pathName: '/threats' },
    { tabName: 'Logs', tabValue: 'logs', pathName: '/threats' }
  ]


  const doNavigateTab = (pathname:any, tabName:any) => {
    const currentParams = getDecodeURI(location?.search);
    setSelectedTab(tabName);
    currentParams.tab = tabName;
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    currentParams.startDate = startDate;
    currentParams.endDate = endDate;
    history.push({ pathname, search: getEncodedURI(currentParams) });
}

const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
  setAnchorElRef(e.currentTarget)
  setFilterPopup(true);
  // handleClickFilterPopup();
}

const handleChipDelete = (key) => {
  let params: any = getDecodeURI(location.search);
  const updatedInfo = { ...threatFilters };
  delete updatedInfo[key]; // Delete the specific key from the object
  delete params[key];
  dispatch({ type: SET_THREAT_FILTERS, payload: { ...updatedInfo } });
  doNavigate(params);
}
const handleFilterClose = () => {
  setFilterPopup(false)
}

  return (
    <div className="Form">
      <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} 
      loading={loading || authReducer?.userPreferencesLoading 
      } setTimePeriod={setTimePeriod} handleSiteClick={handleSiteClick} userGuide={[true, 'threat']} showWantType={false} showSeverity={true}></CommonFilters>
        <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
      <Grid key="dashboard"  container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="threat-dashboard-NewSummary">
        <Box className='accountsActivity-Filter ThreatChips'>
                    <Fragment>
                        <Button variant="outlined" endIcon={<TuneIcon />} onClick={filterPopClick} className='activityReports-Filter'>
                            Filter
                        </Button>
                        <ThreatChips threatInfo={threatFilters} onDelete={handleChipDelete} />
                        <Menu disableEnforceFocus
                            onClose={() => setFilterPopup(false)}
                            open={filterPopup}
                            className="rc--filter_menu_container_reports rc--filter_menu_container_login"
                            anchorEl={anchorElRef}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            
                            <Grid className="usageFilterComponent">
                                <ThreatFilters  setFilterPopup={() => handleFilterClose()}/>
                            </Grid>     
                        </Menu>
                    </Fragment>
                </Box>
          
          {
            isAuthorizedToViewPage ? [
            
            //     <Grid key="SubHeader" item container className="SubHeader_NewSummary dashboardTabs padding-r-l-0">
            //     <Tabs handleTabValue={handleTabValue} alertStatus={alertStatus} setAlertStatus={setAlertStatus} location={location} />
            //   </Grid>,
              <Grid className="new-summary-dash-container">
                <Grid key="SubHeader" item container className="SubHeader_NewSummary padding-right-17">
                  <ThreatCard search={search} interval={interval} vesselList={authReducer?.getVesselsListing} selectedOu={authReducer.selectedOu} tabValue={0} alertStatus={alertStatus} />
                </Grid>
                <Grid className="summary-charts-comp">
                  {selectedTab == "topthreats" && <ThreatChartsComponent timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {/* {tabValue === 1 && <UpTimeCharts vessels={vessels} timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {tabValue === 2 && <AlertCharts handleSiteClick={handleSiteClick} alertStatus={alertStatus} setAlertsPage={setAlertsPage} />} */}
                </Grid>
                <Grid key="summary" className="summary padding-l-r">
                {selectedTab == "topthreats" && <Top10ThreatTable vessels = {authReducer?.getVesselsListing?.locations}/>}
                {selectedTab == "endpoint" && <UserThreatTable vessels= {authReducer?.getVesselsListing?.locations}  handleSiteClick={handleSiteClick} />}
                {(selectedTab == "logs" || selectedTab == "topthreats") && <AllThreatTable vessels = {authReducer?.getVesselsListing?.locations}  handleSiteClick={handleSiteClick} />}
               
                  {/* { vessels && vessels.length == 1 && tabValue == 0 ? <ActivityTopUserTable /> : tabValue == 0 ? <UsageDashboardTable handleSiteClick={handleSiteClick} /> : null}
                  {tabValue == 1 && <UpTimeTable vessels={vessels} timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {tabValue == 2 && <AlertsTable vessels={vessels} alertStatus={alertStatus} handleSiteClick={handleSiteClick} setAlertStatus={setAlertStatus} alertsPage={alertsPage} setAlertsPage={setAlertsPage} />} */}
                </Grid>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  threatTopWidgetLoading: state?.authReducer?.threatTopWidgetLoading,
  threatTopCrossBarDataLoading: state?.authReducer?.threatTopCrossBarDataLoading,
  topThreatServerityDataLoading: state?.authReducer?.topThreatServerityDataLoading,
  theatBySeverityBarChartLoading: state?.authReducer?.theatBySeverityBarChartLoading,
  top10ThreatTableDataLoading: state?.authReducer?.top10ThreatTableDataLoading,
  allThreatTableDataLoading: state?.authReducer?.allThreatTableDataLoading,
  userThreatTableDataLoading: state?.authReducer?.userThreatTableDataLoading,
  allThreatSiteListLoading: state?.authReducer?.allThreatSiteListLoading,
  allTheratDeviceListLoading: state?.authReducer?.allTheratDeviceListLoading,
  threatFilters: state?.authReducer?.threatFilters,
  threatUniqueThreatNameListLoading: state?.authReducer?.threatUniqueThreatNameListLoading,
  threatUniqueClassListLoading: state?.authReducer?.threatUniqueClassListLoading,
  threatUniqueIpListLoading: state?.authReducer?.threatUniqueIpListLoading,
  threatUniqueDeviceListLoading: state?.authReducer?.threatUniqueDeviceListLoading
});

export default withRouter(
  connect(mapStateToProps, { updatePermission, clearSiteDashboard })(ThreatDashboard)
);
