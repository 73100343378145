import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import './threatChartComponent.css';
import _ from "lodash";
import moment from "moment";
import WanUsagePie from "./threatByServerity";
import { getThreatHistogramBySeverity, getTop10ThreatByName, getTop10ThreatBySeverity } from "../../../actions/Users/authenticateThreatDashboard";
import CrossBarChart from "./CrossBarChart.js";
import { SET_THREAT_FILTERS } from "../../../actions/types";

const ChartsComponent = (props) => {
    const { authReducer, timePeriod, handleSiteClick, getDashboardHistogramUsageperWantype, getDashboardHistogramUsageBySiteCount, getDashboardUsagePieTop10Apps, getDashboardUsageHistogramUpDownLink, getDashboardUsageHistogramBySite, getNewSummaryUsageTable, threatTopCrossBarData , newSummaryStartDate, newSummaryEndDate, newSummarySeverityType, location, history, getTop10ThreatByName, topThreatServerityData, getTop10ThreatBySeverity, getThreatHistogramBySeverity, threatBySeverityBarChart, threatFilters } = props;
    const maxBucketValue = 15;
    const maxPointWidth = 50;
    const minPointWidth = 10;
    const minBucketValue = 5
    const _q = getDecodeURI(location?.search);
    const [topAppsData, setTopAppsData] = useState<any>([]);
    const [usagePerWanType, setUsagePerWanType] = useState<any>([]);
    const [usagePerSite, setUsagePerSite] = useState<any>([]);
    const [barChartData, setBarChartData] = useState<any>([]);
    const [barChartOptions, setBarchartOptions] = useState<any>({});
    const [histogramData, setHistogramData] = useState<any>([]);
    const [histogramBySiteData, setHistogramBySiteData] = useState<any>([]);
    const [isHistogram, setIsHistogram] = useState(false)
    const [bucketValue, setBucketValue] = useState(minBucketValue)
    const [chartView, setChartView] = useState('column');
    const [pieLimit, setPieLimit] = useState(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
    const [appLimit, setAppLimit] = useState(_q.hasOwnProperty('appLimit') ? _q.appLimit : 10);

    const [resetDate, setResetDate] = useState<any>([]);
    const dispatch = useDispatch();
    const [vessels, setVessels] = useState([]);
    const [toggleBtn, setToggleBtn] = useState(_q.hasOwnProperty('toggle') ? true : false);
    // const [siteDataUnit, setSiteDataUnit] = useState('GB');
    const [minBucket, setMinBucket] = useState(0)
    const [maxBucket, setMaxBucket] = useState(100)
    const [defaultMinMax, setDefaultMinMax] = useState({ min: minBucket, max: maxBucket })

    //
    const [crossBarChartData, setCrossBarChartData] = useState([{}])
 
    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "15m";
        } else if (minutes > 720 && minutes <= 1440) {
            return "30m";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "3h";
        } else {
            return "12h";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const handleResetValue = () => {
        setMinBucket(defaultMinMax?.min)
        setMaxBucket(defaultMinMax?.max)
        setHistogramBySiteData(getBucketData(histogramData, bucketValue, minBucket, maxBucket))
    }

    useEffect(() => {
        if (isHistogram) {
            if (_.isEmpty(authReducer?.getVesselsListing))
                return;
            let vessels = [];
            const filteredVessels = authReducer?.filteredVessels;
            if (filteredVessels && filteredVessels.length > 0) {
                vessels = filteredVessels;
            } else {
                vessels = authReducer?.getVesselsListing?.locations;
            }
            setVessels(vessels);
            if (vessels?.length > 0) {
                getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value)
                var duration = moment.duration(newSummaryEndDate.diff(newSummaryStartDate));
                var minutes = duration.asMinutes();
                minutes <= 60 ? setBucketValue(2) : setBucketValue(5)
            }
            else if (vessels?.length === 1) {
                setIsHistogram(false)
            }
            else {
                setHistogramBySiteData([])
            }
        }
    }, [isHistogram, authReducer?.getVesselsListing, authReducer?.filteredVessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType])

    useEffect(() => {
        if (histogramData && histogramData.length > 0) {
            setMinBucket(minBucket)
            setMaxBucket(maxBucket)
            setHistogramBySiteData(getBucketData(histogramData, bucketValue, minBucket, maxBucket));
        }
    }, [minBucket, maxBucket])


    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels]);

    useEffect(() => {
        if (vessels?.length > 0) {
            getTop10ThreatByName(vessels, newSummaryStartDate, newSummaryEndDate, appLimit, newSummarySeverityType?.value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);

            getTop10ThreatBySeverity(vessels, newSummaryStartDate, newSummaryEndDate, appLimit, newSummarySeverityType?.value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);

            // newSummaryWanType?.value == '' &&    getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value) 
            getThreatHistogramBySeverity(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit, selectedBin,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);

            // ((newSummarySeverityType?.value && toggleBtn) || (!newSummarySeverityType?.value && toggleBtn)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit, selectedBin);
            // newSummarySeverityType?.value == '' && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
            // newSummarySeverityType?.value && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit);
        } else {
            setTopAppsData([]);
            setUsagePerWanType([]);
            setUsagePerSite([]);
            setBarChartData([]);
            setHistogramBySiteData([]);
        }
    }, [vessels, newSummarySeverityType,threatFilters])

    useEffect(() => {
        const interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBin(interval);
        if (vessels?.length > 0) {
            getTop10ThreatByName(vessels, newSummaryStartDate, newSummaryEndDate, appLimit, newSummarySeverityType?.value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
            getTop10ThreatBySeverity(vessels, newSummaryStartDate, newSummaryEndDate, appLimit, newSummarySeverityType?.value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
            getThreatHistogramBySeverity(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit, interval,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);

            // newSummarySeverityType?.value == '' && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
            // newSummaryWanType?.value == '' && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '');
            // newSummaryWanType?.value && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value);
            // newSummarySeverityType?.value && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit);
        }
    }, [newSummaryStartDate, newSummaryEndDate])

    const divideRange = (start, end, x) => {
        const step = (end - start) / x;
        const result: Array<{ start, end }> = [];

        for (let i = 0; i < x; i++) {
            const rangeStart = start + i * step;
            const rangeEnd = rangeStart - 1 + step;

            result.push({ start: rangeStart.toFixed(0), end: rangeEnd.toFixed(0) });
        }

        return result;
    }

    const handleBucket = (e) => {
        const value = e.target.value;
        setBucketValue(value);
        // setMinBucket(Number(((histogramData[0][2])/(1024*1024*1024)).toFixed(0)))
        // setMaxBucket(Number(((histogramData[histogramData.length - 1][2])/(1024*1024*1024)).toFixed(0)))
        setHistogramBySiteData(getBucketData(histogramData, value, minBucket, maxBucket));
    }

    const handleLegend = (name) => {
        let legendData = histogramBySiteData.filter(item => item.name === name);
        setHistogramBySiteData(legendData)
    }


    const getBucketData = (rows, bucketValue, start, end) => {
        const dividedRanges = divideRange(start, end, bucketValue);
        const resultArray: number[] = [];
        const rangeArray: {}[] = [];

        for (const obj of dividedRanges) {

            let xVal = 0;
            const start = parseInt(obj.start, 10);
            const end = parseInt(obj.end, 10);

            const filteredValues = rows.filter((innerArray) => {
                const value = Number(((innerArray[2]) / (1024 * 1024 * 1024)).toFixed(2));
                return value >= start && value < end;
            });

            resultArray.push(filteredValues.length);

            xVal = filteredValues.map(item => item[2]).reduce((acc, currentValue) => acc + currentValue, 0);

            let valueInBytes = Math.round(xVal / filteredValues.length)
            let valInGB = valueInBytes / (1024 * 1024 * 1024)

            rangeArray.push({
                name: `${obj.start} - ${obj.end} GB`,
                data: [{ x: valInGB ? valInGB : 0, y: filteredValues.length }]
            })
        }
        return rangeArray;
    }

    useEffect(() => {
        if (!_.isEmpty(threatTopCrossBarData)) {
            const formattedData = threatTopCrossBarData?.data.rows.map(row => ({
                categories: row[0],
                severity: row[1],
                count: row[2],
                visible: true
            }));
            setCrossBarChartData(formattedData)

        }
    }, [threatTopCrossBarData]);

    useEffect(() => {
        if (!_.isEmpty(threatBySeverityBarChart)) {
            const data = threatBySeverityBarChart?.data?.total_hits;
            if (data) {
                let lineChartData: any = Object.keys(data?.series)?.map(key => {
                    let name = (key == 'CRITICAL') ? "CRITICAL" : (key == 'HIGH') ? 'HIGH' : (key == 'MEDIUM') ? 'MEDIUM' : 'LOW';
                    let color = (key == 'CRITICAL') ? '#D62728' : (key == 'HIGH') ? '#FF7F0E' : (key == 'MEDIUM') ? '#FFBF00' : '#1F77B4';
                    return { name, data: data?.series[key], marker: { symbol: 'circle' }, visible: true, color }
                })
                let Arr: any = [];
                if (lineChartData[0]) {
                    Arr.push(lineChartData[0])
                }
                if (lineChartData[1]) {
                    Arr.push(lineChartData[1])
                }
                if (lineChartData[2]) {
                    Arr.push(lineChartData[2])
                }
                if (lineChartData[3]) {
                    Arr.push(lineChartData[3])
                }
                setBarChartData(Arr);
                if (Arr) {
                    let names: any = [];
                    Arr?.forEach((el) => {
                        names.push(el.name)
                    })
                    // setLegends(names)
                }
            }

        }

    }, [threatBySeverityBarChart, chartView, newSummarySeverityType])


    useEffect(() => {
        if (!_.isEmpty(topThreatServerityData)) {
            const data = topThreatServerityData?.hasOwnProperty('data') ? topThreatServerityData?.data : {};

            if (data) {
                const Usage = data?.rows && data?.rows?.map((item) => {
                    const color = item[0] == 'CRITICAL' ? '#d62728' : item[0] == 'HIGH' ? '#ff7f0e' : item[0] == 'MEDIUM' ? '#ffbf00' : item[0] == 'LOW' ? '#1f77b4' : '#1f77b4';
                    return { x: item[0], y: item[1], z: item[2], color }
                })

                // here
                setUsagePerWanType(Usage);
            }
        }
    }, [ topThreatServerityData])

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end: any = moment(new Date(Math.floor(event.xAxis[0].max)));
            const timeBand = Math.ceil(((new Date(end)).getTime() - (new Date(start)).getTime()) / 60e3);
            timeBand < 5 ? start = moment(Date.now()).subtract(5, 'minutes') : start = moment(new Date(Math.ceil(event.xAxis[0].min)));
            timeBand < 5 ? end = moment(Date.now()) : end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');

            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const wanOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        colors: [ '#D62728','#FF7F0E', '#FFBF00', '#1F77B4'],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            pointFormat: '<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>',
            valueDecimals: 0,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: newSummarySeverityType?.value == '' && !toggleBtn ? usagePerWanType : newSummarySeverityType?.value == '' && toggleBtn ? usagePerSite : newSummarySeverityType?.value && !toggleBtn ? usagePerWanType : usagePerSite,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    // const dataUnit = newSummarySeverityType?.value == '' && !toggleBtn ? barChartDataUnit : newSummarySeverityType?.value && !toggleBtn ? upDownDataUnit : siteDataUnit;
    useEffect(() => {
        const barOptions: any = {
            time: {
                timezone: timeZone
            },
            chart: {
                type: 'column',
                height: '360px',
                zoomType: 'x',
                plotBorderWidth: 1,
                resetZoomButton: {
                    theme: {
                        style: {
                            display: 'none'
                        }
                    }
                },
                events: {
                    selection: chartAreaSelection()
                },
                style: {
                    fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
                },
            },
            colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
                '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
                '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
                '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
                '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
            ],
            title: {
                text: '',
                align: 'left',
                floating: true,
                x: 12,
                y: 32,
                style: {
                    fontWeight: '500'
                }
            },
            lang: {
                noData: "No Data",
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030',
                },
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 1000000,
                    stickyTracking: false,
                    connectNulls: false,
                },
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                colorByPoint: true
            },
            tooltip: {
                valueSuffix: ``,
                headerFormat: '',
                pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
                valueDecimals: 2,
                borderWidth: 1,
                useHTML: true,
                borderRadius: 15,
                backgroundColor: '#060606',
            },
            xAxis: {
                gridLineWidth: 0.5,
                reversed: false,
                type: "datetime",
                maxPadding: 0.05,
                showLastLabel: true,
                labels: {
                    style: {
                        color: '#2F4B82',
                        fontFamily: 'Inter',
                        fontSize: '9px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                    },
                    format: getSingleRecordChartLable(barChartData, timeZone)
                }
            },
            yAxis: {
                tickInterval: 5,
                gridLineWidth: 1,
                labels: {
                    format: `{value}`,
                    style: {
                        color: '#2F4B82',
                        fontFamily: 'Inter',
                        fontSize: '9px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                    }
                },
                title: {
                    text: `count`,
                }
            },
            series: barChartData,
            colorByPoint: true
        }
        setBarchartOptions(barOptions)

    }, [newSummarySeverityType, barChartData, chartView])

    const handleLegendClick = (index) => {
        let all_true = true;
        let updatedData: any = !newSummarySeverityType?.value && !(chartView === 'column') ? [...barChartData] : [...crossBarChartData];
        // let updatedData: any = newSummarySeverityType?.value == '' && !(chartView === 'column') ? [...barChartData] : newSummarySeverityType?.value && !toggleBtn ? [...upDownData] : [...siteChartUsage];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })
        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })

            !newSummarySeverityType?.value && !(chartView === 'column') ? setBarChartData([...updatedData]) : setCrossBarChartData([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            !newSummarySeverityType?.value && !(chartView === 'column') ? setBarChartData([...updatedData]) : setCrossBarChartData([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            !newSummarySeverityType?.value && !(chartView === 'column') ? setBarChartData([...updatedData]) : setCrossBarChartData([...updatedData]);
        }
    }

    const [visibleSettings, setVisibleSettings] = useState({
        critical: true,
        high: true,
        medium: true,
        low: true,
    });

    const handleLegendClickCrossBar = (index) => {
        // Create a new object for updated visibility settings
        let updatedVisibleSettings;

        // Determine if only one item is currently visible and if it's the one being clicked
        const isSingleVisible = Object.values(visibleSettings).filter(Boolean).length === 1;
        const currentVisibility = Object.values(visibleSettings)[index];

        if (isSingleVisible && currentVisibility) {
            // If the clicked item is the only visible one, set all to true
            updatedVisibleSettings = {
                critical: true,
                high: true,
                medium: true,
                low: true,
            };
        } else {
            // Otherwise, set the clicked item to true and others to false
            updatedVisibleSettings = {
                critical: false,
                high: false,
                medium: false,
                low: false,
            };

            // Map the index to the corresponding severity and set it to true
            switch (index) {
                case 0:
                    updatedVisibleSettings.critical = true;
                    break;
                case 1:
                    updatedVisibleSettings.high = true;
                    break;
                case 2:
                    updatedVisibleSettings.medium = true;
                    break;
                case 3:
                    updatedVisibleSettings.low = true;
                    break;
                default:
                    break;
            }
        }

        // Update the state with the new settings
        setVisibleSettings(updatedVisibleSettings);
    }

    const handleChangePieLimit = (e) => {
        const value = e.target.value;
        setPieLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.pieLimit : params.pieLimit = value;
        doNavigate(params);
        // vessels && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value);
        // vessels && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value, selectedBin);
    }

    const handleChangePieAppLimit = (e) => {
        const value = e.target.value;
        setAppLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.appLimit : params.appLimit = value;
        doNavigate(params);
        vessels && getDashboardUsagePieTop10Apps(vessels, newSummaryStartDate, newSummaryEndDate, value);
    }

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleWanTypeClick = (row) => {
        // const selectedWan = {label: row.x == 'CELLULAR' ? 'LTE' : row.x, value: row.value};
        let params: any = getDecodeURI(location?.search);
        params.severityType = row?.x;
        doNavigate(params);
        // dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: selectedWan });
    }

    const handleChangeToggle = (e) => {
        const value = e.target.checked;
        setToggleBtn(value);
        let params: any = getDecodeURI(location?.search);
        value ? params.toggle = true : delete params.toggle;
        doNavigate(params);
        // newSummarySeverityType?.value == '' && value && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
        // newSummaryWanType?.value == '' && !value && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '');
        // newSummaryWanType?.value && !value && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value);
        // newSummarySeverityType?.value && value && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit);
        // newSummaryWanType?.value == '' && !value &&   getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, '');
        // newSummarySeverityType?.value == '' && !value && getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '', selectedBin);
        // newSummarySeverityType?.value && !value && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, selectedBin);
        // ((!newSummarySeverityType?.value && value) || (newSummarySeverityType?.value && value)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit, selectedBin);
    };

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(e.target.value);
        // if (vessels?.length > 0) {
        //     newSummarySeverityType?.value == '' && !toggleBtn && getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value);
        //     newSummarySeverityType?.value && !toggleBtn && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value);
        //     ((newSummarySeverityType?.value && toggleBtn) || (!newSummarySeverityType?.value && toggleBtn)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, pieLimit, value);
        // }
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    const handleClassClick = (threatClassName) => {
        let params: any = getDecodeURI(location?.search);
        params.threatClass = threatClassName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, threatClass: threatClassName } });
    }

    return (
        <div className="new-summary-chart-container">
            <Grid container spacing={2} className='new-summary-chart-component'>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        {
                            <Grid item xs={3} sm={3} md={3} lg={3} className="pie-chart-summary">
                                <WanUsagePie
                                    title='Threat By Severity'
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={wanOptions}
                                    usagePerWanType={usagePerWanType}
                                    handleWanTypeClick={handleWanTypeClick}
                                />
                            </Grid>
                        }
                        {<Grid item xs={9} sm={9} md={9} lg={9}>
                            <Grid className={resetDate.length > 0 ? "legends-pie-new-container removeMarginTop" : "legends-pie-new-container"}>
                                {chartView == 'spline' ? <Grid className="time-series-legends-new">
                                    {barChartData && barChartData.map((usage, i) => (
                                        <Grid className="summary-pie-bar-legends">
                                            <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                                            <span className={barChartData[i]?.visible ? "barChart-legends" : 'disable-barChart-legends'} onClick={() => handleLegendClick(i)}>{usage.name}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                                    :
                                    <Grid className="time-series-legends-new">
                                        {barChartData && barChartData.map((usage, i) => {
                                            // Determine the corresponding visibility setting based on the index
                                            let isVisible;
                                            switch (i) {
                                                case 0:
                                                    isVisible = visibleSettings.critical;
                                                    break;
                                                case 1:
                                                    isVisible = visibleSettings.high;
                                                    break;
                                                case 2:
                                                    isVisible = visibleSettings.medium;
                                                    break;
                                                case 3:
                                                    isVisible = visibleSettings.low;
                                                    break;
                                                default:
                                                    isVisible = true; // Default to true if index is out of range
                                            }

                                            return (
                                                <Grid key={i} className="summary-pie-bar-legends">
                                                    <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                                                    <span
                                                        className={isVisible ? "barChart-legends" : 'disable-barChart-legends'}
                                                        onClick={() => handleLegendClickCrossBar(i)}
                                                    >
                                                        {usage.name}
                                                    </span>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>}
                                <Grid className="line-bar-view">
                                    {chartView == 'spline' && <Grid className="pieChat-selectTag">
                                        <FormControl variant="standard" className='selectEntry-pie'>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectedBin}
                                                onChange={handleBinChange}
                                            >
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30m'}>30m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2h'}>2h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3h'}>3h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Grid>}
                                    <img className="firstchartBtn" onClick={() => setChartView('column')} src={chartView == 'column' && !isHistogram ? barIcon : bar_chart} />
                                    <img onClick={() => setChartView('spline')} src={chartView == 'spline' ? line_chart : lineIcon} />

                                </Grid>
                                {resetDate.length > 0 &&
                                    <div>
                                        <FormControlLabel
                                            value="Zoom Back"
                                            control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="Zoom Reset"
                                            control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                    </div>
                                }
                            </Grid>
                            {chartView == 'column' ? <CrossBarChart crossBarChartData={crossBarChartData} visibleSettings={visibleSettings} handleClassClick={handleClassClick}/> :
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={barChartOptions}
                                />}
                        </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    threatTopCrossBarData: state?.authReducer?.threatTopCrossBarData,
    topThreatServerityData: state?.authReducer?.topThreatServerityData,
    threatBySeverityBarChart: state?.authReducer?.threatBySeverityBarChart,
    newSummarySeverityType: state?.authReducer?.newSummarySeverityType,
    threatFilters: state?.authReducer?.threatFilters
});

export default withRouter(
    connect(mapStateToProps, { getTop10ThreatByName, getTop10ThreatBySeverity, getThreatHistogramBySeverity})(ChartsComponent)
);