import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, Dialog, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Search } from '@mui/icons-material';
import { Edit } from "@material-ui/icons";
import { Pagination } from "../../Pagination";
import './CustomPlans.css';
import { getDecodeURI, getEncodedURI } from '../../../utils/util';
import SortArrows, { SortOrder } from "../../SortArrows";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import Download from '../../../asset/image/DownloadUsage.svg';
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import _ from "lodash";
import CreateServiceLineDialog from "../CreateServiceLineDialog";
import { DateTimePicker, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import OrgSelection from "../../OrgSelection";
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCustomPlansList, getParentPoolList, createCustomPlan, updateCustomPlan } from "../slice";
import { getDPList } from '../../../actions/Users/authenticateInventory'
import moment from "moment";
import OrgSelectionMultiple from "../../OrgSelectionMultiple";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import momenttz from "moment-timezone";


const convertToCsv = (data: any[][]) => {
    const csvRows: any[] = [];
    data.forEach((row) => {
        csvRows.push([row[5], row[3], row[8], row[10], row[9], row[7], row[2]])
    })
    return [['Organization', 'Sub Organization', 'Starlink ID', 'Serial Number', 'KIT Number', 'Service Line', 'K4 Site'], ...csvRows]
}

interface CustomPlansProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    userTerminals: any[];
    totalUserTerminals: number;
    downloadAsCSV: (parameters: any) => void;
    updateUserTerminalOrganization: (parameters: any) => void;
    newSummaryServiceLine: any;
    serviceLines: any;
    starlinkCloud: any;
    getCustomPlansList: (parameters: any) => void;
    getParentPoolList: (parameters: any) => void;
    createCustomPlan: (parameters: any) => void;
    customPlansCount: any;
    customPlansList: any;
    parentPoolList: any;
    updateCustomPlan: any;
    getDPList: (search?: string) => void;
}

export type CustomPlansSortBy = 'planName' | 'serviceAccount' | 'planSize' | 'duration' | 'subOrg';

function CustomPlans(props: CustomPlansProps) {

    const { authReducer, history, location, userTerminals, getDPList, newSummaryServiceLine, getCustomPlansList, getParentPoolList, createCustomPlan, updateCustomPlan, customPlansList, customPlansCount, dpIds, parentPoolList } = props;
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const _q = getDecodeURI(location?.search)
    const [searchText, setSearchText] = useState<string>('');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: CustomPlansSortBy;
        sortOrder: SortOrder;
        search: string;
        terminalStatus: string[];
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy') as CustomPlansSortBy : 'planName',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
        terminalStatus: queryParams.has('terminalStatus') ? queryParams.get('terminalStatus')?.split(',') as string[] : ['deployed', 'in-warehouse']
    });
    const [showNote, setShowNote] = useState<boolean>(true);
    const ouId = _q.hasOwnProperty("ouId") ? _q.ouId : ""
    const [createPlanDialog, setCreatePlanDialog] = useState<boolean>(false);
    const [editPlanData, setEditPlanData] = useState<any>(null);
    const [parentPools, setParentPools] = useState<any>([]);
    const [openCreateServiceLineDialog, setOpenCreateServiceLineDialog] = useState<boolean>(false);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [poolInfo, setPoolInfo] = useState<any>(null)
    const [dpIdsToKeep, setDpIdsToKeep] = useState(dpIds)
    const dispatch = useDispatch();
    const [createPlanRows, setCreatePlanRows] = useState<any>([
        {
            planName: '',
            parentPool: "",
            recurrence: true,
            duration: 'monthly',
            planUsage: '',
            billingDate: null,
            unit: 'gb'

        },
    ]);
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [error, setError] = useState('');
    const [planInfo, setPlanInfo] = useState('')
    const [entries, setEntries] = useState([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'optOut' },]);
    const [selectedDp, setSelectedDp] = useState([])
    const handleValueChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleAddEntry = () => {
        setEntries([...entries, { usagePercentage: '', type: '' }]);
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };

    const handlePlanRowChange = (index, field, value) => {
        const updatedRows = createPlanRows.map((row, i) => (
            i === index ? { ...row, [field]: value } : row
        ));
        setCreatePlanRows(updatedRows);
    };

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: CustomPlansSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        queryParams.set('terminalStatus', tableParams.terminalStatus.join(','))
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        getCustomPlansList({
            dpIds: dpIds,
            searchText: tableParams.search,
            sortBy: "planSize",
            sortOrder: "asc",
            limit: tableParams.size,
            offset: (tableParams.page - 1) * tableParams.size
        })

        getDPList()
    }, [tableParams, newSummaryServiceLine]);

    useEffect(() => {
        if (!_.isEmpty(dpIds)) {
            getParentPoolList({
                dpIds: dpIds,
                searchText: tableParams.search,
                sortBy: "poolName",
                sortOrder: "desc",
                limit: 20,
                offset: (tableParams.page - 1) * tableParams.size,
                childPools: true
            })

            getCustomPlansList({
                dpIds: dpIds,
                searchText: tableParams.search,
                sortBy: "planSize",
                sortOrder: "asc",
                limit: tableParams.size,
                offset: (tableParams.page - 1) * tableParams.size
            })
        }
        setDpIdsToKeep(dpIds)
    }, [dpIds])



    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'user-terminal') {
                const data = authReducer.csvDataDownloded;
                if (data === null) {
                    return;
                }
                if (data && data.length > 0) {
                    const keys = ['accountNumber', 'dpId', 'k4SiteName', 'dpName', 'accountDpId', 'accountDpName', 'serviceLineNumber', 'serviceLineName', 'userTerminalId', 'kitSerialNumber', 'dishSerialNumber', 'terminalStatus', 'k4DeviceId', 'k4SiteId', 'total_count'];

                    const arrayOfObjects = data.map((data, rowIndex) => {
                        if (rowIndex === 0) return null;
                        const obj = {};
                        keys.forEach((key, index) => {
                            obj[key] = data[index];
                        });
                        return obj;
                    }).filter(obj => obj !== null);
                    if (arrayOfObjects.length) {
                        DownloadCSV(convertToCsv([...arrayOfObjects.map(row => Object.values(row))]), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                5: 'ESC-COMMA',
                                6: 'ESC-COMMA'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const updateCustomPlans = async () => {
       let res:any =  await updateCustomPlan({
            customPlanId: editPlanData?.customPlanId,
            credentialId: editPlanData?.credentialId,
            parentPoolId: editPlanData?.parentPoolDetails?.id,
            accountNumber: editPlanData?.accountNumber,
            childPoolId: poolInfo?.parent === "No" ? poolInfo?.childPoolId : null,
            createdBy: ouId,
            planName: createPlanRows[0].planName,
            planSize: createPlanRows[0].planUsage,
            isRecurring: createPlanRows[0].recurrence,
            planInfo: planInfo,
            dpIds: selectedDp && selectedDp.length > 0 ? selectedDp?.map((dp: any) => { return dp.id; }) : [],
            duration: createPlanRows[0].duration,
            planUsage: createPlanRows[0].planUsage,
            billingDate:moment.tz().startOf('month').toISOString(),
            unit: createPlanRows[0].unit,
            usersToNotify: emails,
            entries: entries,
            notificationMethods: [
                "email"
            ]
        })

        if (res?.success) {
            toast.success(`${createPlanRows[0].planName} updated successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {
            toast.error(`Unable to update ${createPlanRows[0].planName} `, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
        setCreatePlanDialog(false)
        await getCustomPlans()
        clearData()

    }

    const createCustomPlans = async () => {
       let res:any =  await createCustomPlan({
            credentialId: poolInfo?.credentialId,
            parentPoolId: poolInfo?.parentPoolId,
            accountNumber: poolInfo?.accountNumber,
            childPoolId: poolInfo?.parent === "No" ? poolInfo?.childPoolId : null,
            createdBy: ouId,
            planName: createPlanRows[0].planName,
            planSize: createPlanRows[0].planUsage,
            isRecurring: createPlanRows[0].recurrence,
            planInfo: planInfo,
            dpIds: selectedDp && selectedDp.length > 0 ? selectedDp?.map((dp: any) => { return dp.id; }) : [],
            duration: createPlanRows[0].duration,
            planUsage: createPlanRows[0].planUsage,
            billingDate:moment.tz().startOf('month').toISOString(),
            unit: createPlanRows[0].unit,
            usersToNotify: emails,
            entries: entries,
            notificationMethods: [
                "email"
            ]
        })
        if (res?.success) {
            toast.success(`${createPlanRows[0].planName} created successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {
            toast.error(`Unable to create ${createPlanRows[0].planName} `, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
        setCreatePlanDialog(false)
        await getCustomPlans()
        clearData()

    }

    const getCustomPlans = () => {
        getCustomPlansList({
            dpIds: dpIds,
            searchText: tableParams.search,
            sortBy: "planSize",
            sortOrder: "asc",
            limit: tableParams.size,
            offset: (tableParams.page - 1) * tableParams.size
        })
    }

    const clearData = () => {
        setCreatePlanRows([
            {
                planName: '',
                parentPool: "",
                recurrence: true,
                duration: 'monthly',
                planUsage: '',
                billingDate: null,
                unit: 'gb'

            },
        ])
        setPlanInfo('')
        setEmails([]);
        setEntries([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'optOut' },]);
        setSelectedDp([])
        setEditPlanData(null)
    }

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    useEffect(() => {
        let parentPool: any = []
        if (parentPoolList && parentPoolList.length > 0) {
            for (let i = 0; i < parentPoolList.length; i++) {
                parentPool.push({
                    parent: "Yes",
                    parentPoolId: parentPoolList[i].parentPoolId,
                    poolName: parentPoolList[i].poolName,
                    accountNumber: parentPoolList[i].accountNumber,
                    credentialId: parentPoolList[i].credentialId
                })
                if (parentPoolList[i].childPools) {
                    for (let j = 0; j < parentPoolList[i].childPools.length; j++) {
                        let obj = {
                            parent: "No",
                            parentPoolId: parentPoolList[i].parentPoolId,
                            childPoolId: parentPoolList[i].childPools[j].childPoolId,
                            poolName: parentPoolList[i].childPools[j].childPoolName,
                            dpId: parentPoolList[i].childPools[j].dpId,
                            organizationName: parentPoolList[i].childPools[j].organizationName,
                            accountNumber: parentPoolList[i].accountNumber,
                            credentialId: parentPoolList[i].credentialId
                        }
                        parentPool.push(obj)
                    }
                }
            }
        }
        setParentPools(parentPool)
    }, [parentPoolList])

    const [allDpsData, setAllDpsData] = useState<any>([])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getDPList?.dps) && authReducer?.getDPList?.dps?.length > 0) {
            setAllDpsData(authReducer?.getDPList?.dps[0]?.parent_of)
        }
    }, [authReducer.getDPList]);



    useEffect(() => {
        setSelectedDp([])
        if (poolInfo?.parent === "Yes") {
            setDpIdsToKeep(dpIds)
            let ou: any = dpIds.map((d) => {
                return {
                    "id": d,
                    "name": allDpsData && allDpsData.length > 0 ? allDpsData?.find(org => org?.id === d)?.name : "",
                    "level": 2,
                    "hasChildren": false
                }
            })
            setSelectedDp(ou)

        } else if (poolInfo?.parent === "No") {
            setDpIdsToKeep([poolInfo?.dpId])
            let ou: any = [{
                "id": poolInfo?.dpId,
                "name": poolInfo?.organizationName,
                "level": 2,
                "hasChildren": false
            }]
            setSelectedDp(ou)
        }
    }, [poolInfo])

    useEffect(() => {
        if (editPlanData) {
            setCreatePlanRows([
                {
                    planName: editPlanData?.planName,
                    parentPool: editPlanData?.parentPoolDetails?.id,
                    recurrence: editPlanData?.isRecurring,
                    duration: editPlanData?.duration,
                    planUsage: editPlanData?.planSize,
                    billingDate:moment.tz().startOf('month').toISOString(),
                    unit: editPlanData?.unit,

                },
            ])
            setPlanInfo(editPlanData?.planInfo)
            setEmails(editPlanData?.quota?.usersToNotify);
            setEntries(editPlanData?.quota?.quotaActions?.map((q: any) => { return { usagePercentage: q?.usagePercentage, type: q?.type } }));
            if (editPlanData.subOrganizations) {

                let ou: any = editPlanData?.subOrganizations.map((org) => {
                    return {
                        "id": org?.dpId,
                        "name": org?.organizationName,
                        "level": 2,
                        "hasChildren": false
                    }
                })
                setDpIdsToKeep(editPlanData?.subOrganizations?.map((organization) => { return organization.dpId }))
                setSelectedDp(ou)

            }
        }
    }, [editPlanData])

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid item xs={12} sm={12} md={12} lg={12} paddingBottom={1}>
                {showNote ?
                    <Alert
                        severity="info"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowNote(!showNote);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <Typography component="div" className="starlink--base--font_09x starlink--base--text_start">
                            {/* <Typography component="span" className="starlink--base--font_09x starlink--base--font_bold">"Sub Organization" </Typography> */}
                            Configurations made in this page are for internal purposes only
                        </Typography>
                    </Alert> : null}
            </Grid>
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        CUSTOM PLANS
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>

                    <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-user-ter" }}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={() => { setCreatePlanDialog(true); setPoolInfo(null) }} >Create Plan</Button>
                        {/* {totalUserTerminals > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>} */}
                    </Grid>  </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={customPlansCount} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <span className="font-wt-900">PLAN NAME</span>

                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">SERVICE ACCOUNT</span>
                            </TableCell>
                            {/* <TableCell>
                                    <span className="font-wt-900">POOL NAME</span>
                            </TableCell> */}
                            <TableCell>
                                <span className="font-wt-900">PLAN SIZE</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">DURATION</span>
                            </TableCell>

                            <TableCell>
                                <span className="font-wt-900">SUB ORGANIZATIONS</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">ACTIONS</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            customPlansList && customPlansList?.length > 0 ? customPlansList.map((plan) => {
                                return <TableRow
                                    key={plan?.planName}
                                >
                                    <TableCell>
                                        {plan?.planName}
                                    </TableCell>
                                    <TableCell>
                                        {plan?.parentPoolDetails?.poolName}
                                    </TableCell>
                                    {/* <TableCell>
                                        {plan.poolAccount}
                                    </TableCell> */}
                                    <TableCell>
                                        {plan?.planSize} {plan?.unit?.toUpperCase()}
                                    </TableCell>
                                    <TableCell>
                                        {plan?.duration && plan.duration[0].toUpperCase() + plan.duration.slice(1)}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={plan?.subOrganizations?.map((org, index) => {
                                                if (index == 0) { return org?.organizationName }
                                                else { return ", " + org?.organizationName }
                                            })}>
                                            <span>
                                                {
                                                    plan?.subOrganizations
                                                        ?.slice(0, 2)
                                                        .map(org => org.organizationName)
                                                        .join(", ") +
                                                    (plan?.subOrganizations?.length > 2
                                                        ? ` +${plan.subOrganizations.length - 2}`
                                                        : '')
                                                }
                                            </span>

                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit Plan"><IconButton size='small'
                                            onClick={() => {
                                                setCreatePlanDialog(true);
                                                setEditPlanData(plan)
                                                setPoolInfo(parentPools.find(pool => pool.parentPoolId === plan.parentPoolDetails.id))

                                            }}>
                                            <Edit fontSize="small" />
                                        </IconButton></Tooltip>

                                    </TableCell>

                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <CreateServiceLineDialog open={openCreateServiceLineDialog} onClose={() => { setOpenCreateServiceLineDialog(false) }} onSave={() => { }} />

            <Dialog open={createPlanDialog} onClose={() => { setCreatePlanDialog(false); clearData() }} aria-labelledby="service_account--dialog" maxWidth="lg" fullWidth>
                <Grid container spacing={2} style={{ padding: '1em' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div" align="center" style={{ fontWeight: 'bold', color: "#424141" }}>
                            {editPlanData ? "Update" : "Create"}  Custom Plan
                        </Typography>
                    </Grid>
                    <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography fontSize={"0.8rem"}>Note: Create a Custom Plan that is associated with a Sub Organization Pool Account, or even the Primary Starlink Pool Account.   The Custom Plan is assigned to one or more Service Lines.</Typography>
                    </Grid>

                    {/* Row Container */}
                    <Grid item xs={12} >
                        {createPlanRows.map((row, index) => (
                            <>
                                <Grid container key={index} spacing={2} alignItems="center" style={{ marginTop: '8px' }}>

                                    <Grid item xs={2}>
                                        <Typography variant="subtitle1">Plan Name</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle1">Pool</Typography>
                                    </Grid>
                                    <Grid item xs={2.3}>
                                        <Typography variant="subtitle1">Organization</Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Typography variant="subtitle1">Duration</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="subtitle1">Plan Usage</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="subtitle1">Unit</Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography variant="subtitle1">Billing Date</Typography>
                                    </Grid>
                                    <Grid item xs={0.5}>
                                        <Typography variant="subtitle1">Recurring</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} key={index} alignItems="center">

                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth
                                            disabled={editPlanData}
                                            value={row.planName}
                                            onChange={(e) => handlePlanRowChange(index, 'planName', e.target.value)}
                                            size="small"
                                            placeholder="For eg. Custom 100GB Plan"
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Select
                                            fullWidth
                                            size="small"
                                            disabled={editPlanData}
                                            value={poolInfo}
                                            onChange={(e) => {
                                                setPoolInfo(e.target.value);
                                            }}
                                            displayEmpty
                                            renderValue={(selected) =>
                                                selected && selected.poolName.length > 14
                                                    ? `${selected.poolName.slice(0, 14)}...`
                                                    : selected ? selected.poolName : ''
                                            }
                                        >
                                            {parentPools?.map((pool) => (
                                                <MenuItem key={pool.poolName} value={pool}>
                                                    <div style={{ paddingLeft: pool?.parent === "No" ? "20px" : "0px" }}>
                                                        {pool?.parent === "No" ? "-" : ""} {pool.poolName}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </Grid>

                                    <Grid item xs={2.3}>
                                        <OrgSelectionMultiple
                                            multiple={true}
                                            dpIdsToKeep={dpIdsToKeep}
                                            value={selectedDp}
                                            disabled={dpIdsToKeep.length === 1 || editPlanData || poolInfo === null}
                                            onChange={(e) => handleChangeDp(e)} />
                                    </Grid>

                                    <Grid item xs={1.2}>
                                        <Select
                                            fullWidth
                                            disabled={editPlanData}
                                            size="small"
                                            value={row.duration}
                                            onChange={(e) => handlePlanRowChange(index, 'duration', e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>Select Duration</MenuItem>
                                            <MenuItem value="monthly">Monthly</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            disabled={editPlanData}
                                            value={row.planUsage}
                                            onChange={(e) => handlePlanRowChange(index, 'planUsage', e.target.value)}
                                            size="small"
                                            placeholder="100"
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Select
                                            fullWidth
                                            size="small"
                                            disabled={editPlanData}
                                            value={row.unit}
                                            onChange={(e) => handlePlanRowChange(index, 'unit', e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="gb">GB</MenuItem>
                                            <MenuItem value="tb">TB</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={1.5}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                disabled={editPlanData}
                                                value={momenttz().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0)}
                                                onChange={(newValue) => handlePlanRowChange(index, 'billingDate', newValue)}
                                                slots={{
                                                    textField: TextField,
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        InputProps: {
                                                            style: {
                                                                height: '40px',
                                                                padding: '0 14px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            readOnly: true,
                                                        },
                                                        InputLabelProps: {
                                                            style: {
                                                                lineHeight: 'normal',
                                                            },
                                                        },
                                                    },
                                                }}
                                                readOnly
                                                disableOpenPicker
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={0.5}>
                                        <Checkbox
                                            // disabled={editPlanData}
                                            checked={row.recurrence}
                                            onChange={(e) => handlePlanRowChange(index, 'recurrence', e.target.checked)}
                                        />
                                    </Grid>

                                </Grid>
                            </>
                        ))}
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Plan Info
                            </Typography>
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={9}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                value={planInfo}
                                onChange={(e) => { setPlanInfo(e.target.value) }}
                                size="small"
                                placeholder="For eg. Some Description about plan"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                        {entries.map((entry, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <Grid item xs={0.6}></Grid>
                                <Grid item xs={1}>
                                    <Typography>Quota Action </Typography>
                                </Grid>
                                <Grid item xs={0.6}></Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            label="% used"
                                            variant="outlined"
                                            value={entry.usagePercentage}
                                            type="number"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value) && (value === "" || parseFloat(value) > 0 )) {
                                                    handleValueChange(index, 'usagePercentage', value);
                                                }
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: "0",
                                                step: "any"
                                            }}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={0.25}></Grid>
                                <Grid item xs={4}>
                                    <Select
                                        label="Action"
                                        fullWidth
                                        size="small"
                                        value={entry.type}
                                        onChange={(e) => handleValueChange(index, 'type', e.target.value)}
                                    >
                                        <MenuItem value="sendNotification">Send Notification</MenuItem>
                                        <MenuItem value="pause">Pause Service Line</MenuItem>
                                        <MenuItem value="optOut">Mobile Priority (Opt Out)</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={0.5} display="flex" alignItems="center">
                                    {index > 1 && (
                                        <IconButton
                                            onClick={() => handleRemoveEntry(index)}
                                            color="secondary"
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                        </IconButton>
                                    )}
                                    {index === entries.length - 1 && (
                                        <IconButton onClick={handleAddEntry} color="primary">
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.75}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={0.20}></Grid>
                        <Grid item xs={6.5}>
                            <Box>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: '8px',
                                        borderRadius: '10px',
                                        border: '1px solid #c2d3eb',
                                        boxShadow: 'none',
                                    }}
                                >
                                    {emails.map((email, index) => (
                                        <Chip
                                            key={index}
                                            label={email?.email}
                                            onDelete={() => handleDeleteEmail(email)}
                                            sx={{ margin: '4px' }}
                                        />
                                    ))}
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        error={!!error}
                                        placeholder="Press Enter to add email"
                                        helperText={error}
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        onKeyDown={handleAddEmail}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                padding: '0 4px',
                                            },
                                        }}
                                        sx={{
                                            width: 'auto',
                                            flexGrow: 1,
                                            minWidth: '120px',
                                            '& .MuiInputBase-root': {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '8px 4px',
                                                flex: 1,
                                            },
                                        }}
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} display="flex" justifyContent="flex-end" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" className='starlink--button--cancel' style={{ marginRight: '10px' }} onClick={() => { setCreatePlanDialog(false); clearData(); }}> Cancel </Button>
                        <Button variant="outlined" className='starlink--button_contained--primary' onClick={() => { editPlanData ? updateCustomPlans() : createCustomPlans() }}
                            disabled={createPlanRows[0]?.planName === '' || createPlanRows[0]?.planUsage === ''}
                          
                            > {editPlanData ? "Update" : "Add Plan"} </Button>
                    </Grid>
                </Grid>
            </Dialog>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    userTerminals: state.starlinkAdmin.userTerminals,
    totalUserTerminals: state.starlinkAdmin.totalUserTerminals,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    serviceLines: state.starlinkReports.allServiceLines,
    starlinkCloud: state.starlinkCloud,
    customPlansList: state.starlinkAdmin.customPlansList,
    customPlansCount: state.starlinkAdmin.customPlansCount,
    parentPoolList: state.starlinkAdmin.parentPoolList
});


export default withRouter(
    connect(mapStateToProps, {
        downloadAsCSV,
        getCustomPlansList,
        getParentPoolList,
        createCustomPlan,
        updateCustomPlan,
        getDPList
    })(CustomPlans)
);