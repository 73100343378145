import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, FormControl, Select, MenuItem, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, getDecodeURI,getEncodedURI, readableBytes } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { getAllThreatTableData } from "../../../actions/Users/authenticateThreatDashboard";
// import { getUptimeTableData } from "../../actions/Users/authenticateTrends";
import _ from "lodash";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, {clearCsvDownload} from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import "./../ThreatDashboard.css";
import { SET_THREAT_FILTERS } from "../../../actions/types";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[2], row[3], row[4], row[5], row[6],row[7], row[8], row[9],row[10]])
    })
    return [['Site Name', 'Device Name', 'Name', 'Type', 'Severity', 'Action', 'DST IP', 'SRC IP', 'Interface', 'Protocol'], ...csvRows]
}

const AllThreatTable = (props) => {
    const { timePeriod, handleSiteClick, getAllThreatTableData, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType, setDashboardUptimeSiteStatisticsTableLoading, location, history, authReducer, downloadAsCSV ,allThreatTableData,threatFilters} = props;

    const _q = getDecodeURI(location?.search);
    const SITE_NAME_ALL_THREAT = 'Site_Name';
    const DEVICE_NAME = 'Device_Name';
    const SEVERITY = 'severity';
    const TYPE = 'Type';
    const THREAT_NAME = 'Threat_Name';
    const ACTION = 'Action';
    const INTERFACE = 'Interface';
    const PROTOCOL='Protocol';
    const ATTACK_TIME='Attack_Time'

    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const DEFAULT_PAGE_SIZE = 10;
    const ENTER_KEY_CODE = 'Enter';
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sortAllThreat") ? _q.sortAllThreat : SITE_NAME_ALL_THREAT);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const [vessels, setVessels] = useState<any[]>([]);

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if(vessels?.length > 0) {
            const window_value = (timePeriod?.minutes / 5);
            getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, 0, limit, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
            setPage(0);
        } else {
            setTableData([]);
            setCount(0);
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType,threatFilters])

    useEffect(() => {
        if(!_.isEmpty(allThreatTableData)) {
            const data = allThreatTableData?.hasOwnProperty('data') ? allThreatTableData?.data : {};
            const dataArr:any = [];
            setCount(data?.rows?.[0]?.[13]);
            data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [allThreatTableData])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'all-therat-table') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows])
                    , {
                        formatters: {
                            0: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sortAllThreat = field;
        params.sortOrder = order;
        const Page = 0;
        setPage(0);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, field, order, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass)
    }

    const setSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };
    
    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value-1);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value-1, limit, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 0;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(0);
        setLimit(Limit);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, Limit, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 0;
        setPage(0);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, sort, sortOrder, value, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
    };

    const handleChangeTableLimit = (e) => {
        const value = e.target.value;
        setLimit(value);
        const Page = 0;
        setPage(0);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.limit : params.limit = value;
        delete params.page;
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getAllThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, value, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleOnSearchClick = () => {
        doSearch(searchValue);
      }
    
      const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
      }
    
      const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
          searchTimeoutRef.current = undefined;
          doSearch(e.target.value);
        }, 1000)
      }

    const handleDownloadReport = () => {
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
  
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id : "all-therat-table",
            queryName: 'SNORT_DASHBOARD_THREAT_LOG',
            payload: {
                siteid: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                start_time: startDate,
                end_time: endDate,
                "name":  threatFilters?.threatName ? `and msg='${threatFilters?.threatName}'` : "",
                "ip": threatFilters?.ip ? `and ip_address='${threatFilters?.ip}'` : "",
                "class": threatFilters?.threatClass ? `and class='${threatFilters?.threatClass}'` : "",
                "devicename": threatFilters?.deviceName ? `and Device_Name='${threatFilters?.deviceName}'` : "",
                pageOffset: page ? page : 0,
                pageSize: count,
                search: searchValue,
                sort_column: sort ? sort : "priority asc,Attack_Time",
                sort_order:sortOrder ? sortOrder : "desc",
                severity: newSummarySeverityType.value && newSummarySeverityType.value !== "all" ? `and severity='${newSummarySeverityType.value.toUpperCase()}'` : "",
                
            }
            
        }
        downloadAsCSV(params);
    }

    const handleSeverityTypeClick = (severity) => {
        let params: any = getDecodeURI(location?.search);
        params.severityType =severity;
        doNavigate(params);
    }

    const handleThreatClassNameClick = (threatClassName) => {
        let params: any = getDecodeURI(location?.search);
        params.threatClass = threatClassName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, threatClass: threatClassName } });
    }

    const handleThreatDeviceNameClick = (deviceName) => {
        let params: any = getDecodeURI(location?.search);
        params.deviceName = deviceName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, deviceName: deviceName } });
    }

    const rowsOption = [10, 15, 25, 50, 100]
    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">{`All Threats`}</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid item xs={4} sm={4} md={4} lg={4} className="margin-left-auto">
                            <Box>
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    classes={{ root: "input-box-uptime-tbl" }}
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "serachBar",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="cursorPointer"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid className="downloadBtnContainer usage-downloadBtnContainer">
                            <div className="download-summary-btn marg-top-2" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page + 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME_ALL_THREAT)}>SITE NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME_ALL_THREAT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME_ALL_THREAT, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME_ALL_THREAT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME_ALL_THREAT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_NAME)}>DEVICE NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, ASC_ORDER)} />
                                                <img src={sort === DEVICE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    {/* <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, THREAT_NAME)}>NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === THREAT_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THREAT_NAME, ASC_ORDER)} />
                                                <img src={sort === THREAT_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, THREAT_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell> */}
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TYPE)}>CLASS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TYPE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TYPE, ASC_ORDER)} />
                                            <img src={sort === TYPE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, TYPE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SEVERITY)}>SERVERITY</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SEVERITY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SEVERITY, ASC_ORDER)} />
                                            <img src={sort === SEVERITY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, SEVERITY, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ACTION)}>ACTION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ACTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ACTION, ASC_ORDER)} />
                                            <img src={sort === ACTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, ACTION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900">DST IP</span>
                                            {/* <Grid className="sort-icon-margin">
                                            onClick={(event) => switchSorting(event, LTE)}
                                                <img src={sort === LTE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LTE, ASC_ORDER)} />
                                            <img src={sort === LTE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LTE, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >SRC IP</span>
                                            {/* <Grid className="sort-icon-margin">
                                            onClick={(event) => switchSorting(event, VSAT)}
                                                <img src={sort === VSAT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VSAT, ASC_ORDER)} />
                                            <img src={sort === VSAT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, VSAT, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, INTERFACE)}>INTERFACE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === INTERFACE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, INTERFACE, ASC_ORDER)} />
                                            <img src={sort === INTERFACE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, INTERFACE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    {/* <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PROTOCOL)}>PROTOCOL</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PROTOCOL && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PROTOCOL, ASC_ORDER)} />
                                            <img src={sort === PROTOCOL && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PROTOCOL, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell> */}
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ATTACK_TIME)}>ATTACK TIME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ATTACK_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ATTACK_TIME, ASC_ORDER)} />
                                            <img src={sort === ATTACK_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, ATTACK_TIME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900">METADATA</span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !setDashboardUptimeSiteStatisticsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                            <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.Site_Name ? item?.Site_Name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick({k4Id: item?.locationID, x: item?.Site_Name})}>{item?.Site_Name}</TableCell>
                                            </Tooltip>
                                            <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.Device_Name ? item?.Device_Name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleThreatDeviceNameClick(item?.Device_Name)}>{item?.Device_Name ?  item?.Device_Name : '-'}</TableCell>
                                             </Tooltip>
                                             {/* <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.Threat_Name ? item?.Threat_Name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.Threat_Name ?  item?.Threat_Name : '-'}</TableCell>
                                            </Tooltip> */}
                                            <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.Threat_Name ? item?.Threat_Name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleThreatClassNameClick(item?.Type)}>{item?.Type ?  item?.Type : '-'}</TableCell>
                                            </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick" onClick={() => handleSeverityTypeClick(item?.Severity)}>{item?.Severity ?  item?.Severity : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Action ?  item?.Action : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Dst_IP ?  item?.Dst_IP : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Src_IP ?  item?.Src_IP : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Interface ?  item?.Interface : '-'}</TableCell>
                                                {/* <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Protocol ?  item?.Protocol : '-'}</TableCell> */}
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(item?.Attack_Time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    <JsonDataTooltip data={item?.metadata} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryUptimeTable: state.authReducer?.newSummaryUptimeTable,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummarySeverityType:state?.authReducer?.newSummarySeverityType,
    allThreatTableData: state?.authReducer?.allThreatTableData,
    threatFilters: state?.authReducer?.threatFilters
  });
  
  export default withRouter(
    connect(mapStateToProps, { getAllThreatTableData,  downloadAsCSV })(AllThreatTable)
  )