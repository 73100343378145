import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _, { filter } from "lodash";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from '@mui/icons-material/Close';

import { getVesselEdgeTopologyProducts } from "../../../actions/Users/authenticateDashboard";

import "./index.css"
import { FilterMenu } from "../FilterMenu";
import { Box, Grid, Input, InputAdornment, Paper, TextField } from "@mui/material";
import GenerateLinkDialog from "../LinksContainer/GenerateLinkDialog";

function TopBar(props) {

    const { authReducer, errorReducer, siteId, siteName, handleDeviceSelection, handleChangeFilter, handleSearch, getVesselEdgeTopologyProducts, filter } = props

    const [devices, setDevices] = useState<any>();
    const [deviceId, setDeviceId] = useState<string>(!_.isEmpty(devices) ? devices[0].id : '');
    const [search, setSearch] = useState('');
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (!_.isEmpty(siteId)) {
            getVesselEdgeTopologyProducts(1, siteId);
        }
    }, [])

    useEffect(() => {
        let site = authReducer.vesselEdgeTopologyProducts?.data?.manage_location[0];
        if (!_.isEmpty(site)) {
            let _devices = site?.products?.map(p => p?.devices?.map(d => d))?.flat();
            setDevices(_devices);
        }
    }, [authReducer.vesselEdgeTopologyProducts])

    useEffect(() => {
        if (!_.isEmpty(devices)) {
            setDeviceId(devices[0].id);
        }
    }, [devices])

    useEffect(() => {
        handleChangeFilter({
            address: [],
            port: [],
            type: [],
            alias: [],
            isRevoked: []
        })
        handleDeviceSelection(deviceId);
    }, [deviceId])

    const handleChange = (event: SelectChangeEvent) => {
        setDeviceId(event.target.value as string)
    };

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearch(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleOnSearchClear = () => {
        setSearch('');
        handleSearch('');
    }

    const handleKeyDownSearchInput = (e) => {
        if (e.key === "Enter") {
            handleSearch(search)
            e.preventDefault();
        }
    }

    const handleClickSearch = () => {
        handleSearch(search);
    }

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'row', margin: '0.5rem', gap: '0.5rem', alignItems: 'center' }}>
            <Grid>
                <TextField
                    id="search"
                    variant="outlined"
                    placeholder="Type something"
                    classes={{ root: "input-box-rc-tb" }}
                    size="small"
                    value={search}
                    onChange={handleOnSearchChange}
                    onKeyDown={handleKeyDownSearchInput}
                    InputLabelProps={{ className: "serachLabel" }}
                    InputProps={{
                        className: "serachBar",
                        startAdornment: (
                            <InputAdornment position="start">
                                {!search && <SearchIcon
                                    className="cursorPointer input-search-icons" onClick={handleClickSearch}
                                />}
                                {search && <CloseIcon
                                    className="cursorPointer input-search-icons"
                                    onClick={handleOnSearchClear}
                                />}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            {
                !_.isEmpty(devices) ? <Grid item><FormControl sx={{ minWidth: 300 }} size="small">
                    <InputLabel id="select-device-label" sx={{ fontWeight: '600', fontSize: '0.9rem' }} >DEVICE</InputLabel>
                    <Select
                        labelId="select-device-label"
                        id="select-device-label"
                        value={deviceId}
                        label="DEVICE"
                        onChange={handleChange}
                        size="small"
                        autoWidth
                        placeholder='Select Device'
                        inputProps={{ sx: { background: "white", borderRadius: "7px" } }}
                    >
                        {
                            devices.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
                        }
                    </Select>
                </FormControl></Grid> : null
            }
            <Grid>
                <FilterMenu defaultFilter={filter} handleChange={handleChangeFilter}/>
            </Grid>
            <Grid>
                <GenerateLinkDialog siteId={siteId} deviceId={deviceId} />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default connect(mapStateToProps, {
    getVesselEdgeTopologyProducts
})(TopBar)