import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Box, Button, Dialog, Divider, FormControlLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, MenuItem} from "@mui/material";
import { Pagination } from "../../Pagination";
import './UserTerminals.css';
import { getDecodeURI, getEncodedURI } from '../../../utils/util';
import { getUserTerminals, clearComponentData} from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { UserTerminal } from "../types";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { getAllServiceLines } from "../../StarlinkReports/slice";
import _ from "lodash";
import CreateServiceLineDialog from "../CreateServiceLineDialog";

const convertToCsv = (data: any[][]) => {
    const csvRows: any[] = [];
    data.forEach((row) => {
        csvRows.push([row[6], row[4], row[0], row[9].replaceAll(',', ';'), row[10].replaceAll(',', ';'),  row[8], row[3]])
    })
    return [['Organization', 'Sub Organization', 'ACCOUNT NUMBER', 'Serial Number', 'KIT Number', 'Service Line', 'K4 Site'], ...csvRows]
}

interface UserTerminalsProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    userTerminals: any[];
    totalUserTerminals: number;
    getUserTerminals: (parameters: any) => void;
    clearComponentData : () => void;
    downloadAsCSV: (parameters: any) => void;
    updateUserTerminalOrganization: (parameters: any) => void;
    newSummaryServiceLine:any;
    serviceLines: any;
    getAllServiceLines: (parameters: any) => void;
    starlinkCloud:any;
}

export type UserTerminalSortBy = 'serviceLineName' | 'userTerminalId' | 'kitSerialNumber' | 'dishSerialNumber' | 'k4SiteName' | 'accountDpName' | 'dpName';

function UserTerminals(props: UserTerminalsProps) {

    const { authReducer, errorReducer, history, location, dpIds, userTerminals, serviceLines, starlinkCloud, totalUserTerminals, getUserTerminals, clearComponentData, downloadAsCSV, newSummaryServiceLine, getAllServiceLines } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>('');
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: UserTerminalSortBy;
        sortOrder: SortOrder;
        search: string;
        terminalStatus: string[];
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy')  as UserTerminalSortBy: 'userTerminalId',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
        terminalStatus: queryParams.has('terminalStatus') ? queryParams.get('terminalStatus')?.split(',') as string[] : ['deployed']
    });
    const [associateUserTerminalDialog, setAssociateUserTerminalDialog] = useState<boolean>(false); 
    const [accountsData, setAccountsData] = useState<any>([]);
    const [newTerminalId, setNewTerminalId] = useState<any>(null);
    const [currentServiceLine, setCurrentServiceLine] = useState<any>(null);
    const [selectedServiceLine, setSelectedServiceLine] = useState(null);
    const [openCreateServiceLineDialog, setOpenCreateServiceLineDialog] = useState<boolean>(false);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const userTerminalsData:any = []


    const refreshUserTerminalTable = (samePage: boolean = false) => {
        let _page = 1;
        if (samePage) {
            _page = tableParams.page;
        }
        setTableParams({
            ...tableParams,
            page: _page
        });
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: UserTerminalSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const handleChangeTerminalStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _terminalStatus = ['deployed', 'in-warehouse'];
        if (event.target.value === 'deployed') {
            _terminalStatus = ['deployed'];
        } else if (event.target.value === 'in-warehouse') {
            _terminalStatus = ['in-warehouse'];
        }
        setTableParams({
            ...tableParams,
            terminalStatus: _terminalStatus,
            page: 1
        });
    };

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        queryParams.set('terminalStatus', tableParams.terminalStatus.join(','))
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    console.log("aaaaaaaa", tableParams.terminalStatus.includes('in-warehouse'))

    useEffect(() => {
        updateQueryParams();
        clearComponentData();
        getUserTerminals({
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            terminalStatus: tableParams.terminalStatus,
            serviceLines: tableParams.terminalStatus.includes('in-warehouse') ? ['<NOT_CONFIGURED>'] : Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });

        getAllServiceLines({ serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [] });
    }, [ tableParams, newSummaryServiceLine]);

    const handleChangeSelectedServiceLine = (event, newValue) => {
        if(newValue.nickname === "Create New Service Line"){
            setAssociateUserTerminalDialog(false)
            setOpenCreateServiceLineDialog(true)
        }else{
             setSelectedServiceLine(newValue);
        }
       
    };

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'user-terminal') {
                const data = authReducer.csvDataDownloded;
                if (data === null) {
                    return;
                }  
                if (data && data.length > 0) {
                    const keys = ['accountNumber', 'dpId', 'k4SiteName', 'dpName', 'accountDpId', 'accountDpName', 'serviceLineNumber', 'serviceLineName', 'userTerminalId', 'kitSerialNumber', 'dishSerialNumber', 'terminalStatus', 'k4DeviceId', 'k4SiteId', 'total_count'];

                    const arrayOfObjects = data.map((data,rowIndex) => {
                        if (rowIndex === 0) return null;
                      const obj = {};
                      keys.forEach((key, index) => {
                        obj[key] = data[index];
                      });
                      return obj;
                    }).filter(obj => obj !== null);
                    if (arrayOfObjects.length) {
                        DownloadCSV(convertToCsv([ ...arrayOfObjects.map(row => Object.values(row))]), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                5: 'ESC-COMMA',
                                6: 'ESC-COMMA'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'user-terminal',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalUserTerminals,
                offset: 0,
                terminalStatus: tableParams.terminalStatus.map(status => `'${status}'`).join(','),
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_USER_TERMINALS_ADMIN',
        }
        downloadAsCSV(params);
    }

    const options = serviceLines.length > 0
    ? [{ serviceLineNumber: '', nickname: 'Create New Service Line' }, ...serviceLines]
    : [];


    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        USER TERMINALS
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <RadioGroup
                        row
                        aria-labelledby="starlink--service_account_type--radio_group"
                        name="controlled-radio-buttons-group"
                        value={tableParams.terminalStatus.length === 2 ? 'all' : tableParams.terminalStatus[0]}
                        onChange={handleChangeTerminalStatus}
                    >
                        {/* <FormControlLabel value="all" control={<Radio size="small" />} label="All" /> */}
                        <FormControlLabel value="deployed" control={<Radio size="small" />} label="Deployed" />
                        <FormControlLabel value="in-warehouse" control={<Radio size="small" />} label="In Warehouse" />
                    </RadioGroup>
                    {/* <FormControl size="small">
                        <TextField
                            label={'Search'}
                            value={searchText}
                            onChange={handleChangeSearchText}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch() } }}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: '0.5rem' }}
                            InputProps={{
                                endAdornment: <Search onClick={handleSearch} style={{ cursor: 'pointer' }} />
                            }}
                        />
                    </FormControl> */}
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{root: "input-box-user-ter"}}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {/* <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={()=>{setAssociateUserTerminalDialog(true)}} >Add Starlink</Button> */}
                    {totalUserTerminals > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalUserTerminals} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SUB ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            {/* <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="userTerminalId" onChange={handleChangeSorting}>
                                <span className="font-wt-900">STARLINK ID</span>
                                </SortArrows>
                            </TableCell>*/}
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dishSerialNumber" onChange={handleChangeSorting}>
                                <span className="font-wt-900">ACCOUNT NUMBER</span>
                                </SortArrows>
                            </TableCell> 
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="serviceLineName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">SERVICE LINE</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="kitSerialNumber" onChange={handleChangeSorting}>
                                <span className="font-wt-900">KIT NUMBER</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="k4SiteName" onChange={handleChangeSorting}>
                                <span className="font-wt-900">K4 SITE</span>
                                </SortArrows>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            userTerminals?.length > 0 ? userTerminals.map((userTerminal: UserTerminal) => {
                                return <TableRow
                                    key={userTerminal.userTerminalId}
                                >
                                    <TableCell>
                                        {userTerminal.accountDpName}
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.dpName}
                                    </TableCell>

                                    <TableCell>
                                    <Tooltip
                                                classes={{ tooltip: 'starlink--tooltip' }}
                                                title={
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                        <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>ACCOUNT NAME</TableCell>
                                                                    <TableCell>   {userTerminal.accountName}</TableCell>
                                                                </TableRow>
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </div> 
                                                }
                                            >
                                                <span> {userTerminal?.accountNumber}</span>
                                            </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.serviceLineName}
                                    </TableCell>
                                    <TableCell>
                                    <Tooltip
                                                classes={{ tooltip: 'starlink--tooltip' }}
                                                title={
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                        <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>KIT Number </TableCell>
                                                                    <TableCell>   {userTerminal.kitSerialNumber}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Dish Serial Number </TableCell>
                                                                    <TableCell>{userTerminal.dishSerialNumber} </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Starlink ID</TableCell>
                                                                    <TableCell>{userTerminal.userTerminalId}</TableCell>
                                                                </TableRow>
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </div> 
                                                }
                                            >
                                                <span>{userTerminal.kitSerialNumber}</span>
                                            </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {userTerminal.k4SiteName}
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateServiceLineDialog open={openCreateServiceLineDialog}  onClose={()=>{setOpenCreateServiceLineDialog(false)}} onSave={()=>{}} />
            <Dialog open={associateUserTerminalDialog} onClose={() => { setAssociateUserTerminalDialog(false) }} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
                <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                    <Grid container spacing={2} style={{ padding: '1em' }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="div" align="center"   style={{ fontWeight: 'bold', color:"#424141" }}>
                                ADD STARLINK
                            </Typography>
                            <Typography component="div"  style={{ marginTop: '25px' }}>
                                Add a Starlink to this service line  {currentServiceLine?.nickname}.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
                            <Autocomplete
            id="service_line"
            fullWidth
            options={options}
            value={selectedServiceLine}
            onChange={handleChangeSelectedServiceLine}
            size="small"
            getOptionLabel={(option) => `${option.nickname}${option.serviceLineNumber !== "all" ? ` (${option.serviceLineNumber})` : ''}`}
            renderInput={(params) => <TextField {...params} label="Service Line" variant="outlined" />}
            renderOption={(props, option, { index }) => (
                <div key={option.serviceLineNumber}>
                    <MenuItem {...props}>
                        {option.nickname}{option.serviceLineNumber && ` (${option.serviceLineNumber})`}
                    </MenuItem>
                    {index === 0 && (
                        <Box sx={{ width: '100%', bgcolor: '#264C86', height: 1 }}>
                            <Divider />
                        </Box>
                    )}
                </div>
            )}
        />
                        </Grid>
                    </Grid>

                        <Grid item xs={12}>
                            {/* {accountsData && accountsData.userTerminalsArray && accountsData.userTerminalsArray.length > 0 ? */}
                            <Autocomplete
                                fullWidth
                                options={userTerminalsData}
                                getOptionLabel={(option) => `${option?.userTerminal} - ${option?.kitSerialNumber} - ${option?.dishSerialNumber}`}
                                value={userTerminalsData?.find(option => option?.userTerminal === newTerminalId) || null}
                                onChange={(event, newValue) => { setNewTerminalId(newValue?.userTerminal); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Starlink ID, Starlink Serial Number, or Kit Serial Number"
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { height: '48px' }  
                                        }}
                                    />
                                )}
                            />
                            {/* : null } */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button
                            disabled={newTerminalId == null}
                            variant="contained"
                        // onClick={handleSubmit}
                        >
                            ADD STARLINK
                        </Button>
                    </Grid>


                </Grid>
            </Dialog>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    userTerminals: state.starlinkAdmin.userTerminals,
    totalUserTerminals: state.starlinkAdmin.totalUserTerminals,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    serviceLines: state.starlinkReports.allServiceLines,
    starlinkCloud: state.starlinkCloud
    });  
    

export default withRouter(
    connect(mapStateToProps, {
        getUserTerminals,
        downloadAsCSV,
        clearComponentData,
        getAllServiceLines
    })(UserTerminals)
);