import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, TextField, Chip, IconButton, Table, Typography, Button, Stack, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';

interface ServiceLineDevicesProps {
    row: any;
}


const ServiceLineDevices: React.FC<ServiceLineDevicesProps> = ({ row }) => {
    return (
        <div className="optIn-container">
            {row?.userTerminals.split(',') && !_.isEmpty(row?.userTerminals) &&
                <TableContainer component={Paper} className="starlink--table_container" >
                    <Table sx={{ minWidth: 300, marginTop: "10px" }} size="small" aria-label="starlink--service_accounts">
                        <TableHead>
                            <TableRow>
                                <TableCell> <span className="font-wt-900">SNo.</span> </TableCell>
                                <TableCell> <span className="font-wt-900">STARLINK</span> </TableCell>
                                <TableCell> <span className="font-wt-900">ACTIONS</span> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row?.userTerminals.split(',').map((ut, index) => {
                                return (<TableRow>
                                    <TableCell> {index + 1} </TableCell>
                                    <TableCell> {ut} </TableCell>
                                    <TableCell>
                                        <Tooltip title={"Remove Starlink"}>
                                            <IconButton disabled size='small'>
                                                <DeleteIcon />
                                            </IconButton></Tooltip>

                                    </TableCell>
                                </TableRow>)

                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            }

            <Grid style={{ margin: "200px 0px 0px 420px" }}>
                <Button size="small" disabled variant="contained" onClick={() => { }}> ADD STARLINK</Button>
            </Grid>
        </div>
    );
};

export default ServiceLineDevices;
